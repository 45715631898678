import React, { useState } from 'react';
import "./userMain.css";
import { Link, useNavigate } from 'react-router-dom';

export default function PasswordEdit() {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      setPasswordError("Access token not found");
      return;
    }

    if (newPassword !== confirmNewPassword) {
      setPasswordError("New passwords do not match");
      return;
    }

    try {
      const response = await fetch("https://dev-api.ivoz.ai/user/change-password", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          token: accessToken,
        },
        body: JSON.stringify({
          old_password: oldPassword,
          new_password: newPassword,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess(data.message);
        setOldPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      } else {
        if (response.status === 401) {
          navigate("/");
        } else {
          const errorMessage = data.detail && data.detail[0]?.msg;
          if (errorMessage) {
            setPasswordError(errorMessage);
          } else {
            setPasswordError("Failed to change password");
          }
        }
      }
    } catch (error) {
      setPasswordError("Error changing password");
    }
  };

  return (
    <div>
      <h1>Change Password</h1>
      <hr />
      <br />

      {/* Display password error message */}
      {passwordError && (
        <p className="signup-error-message">{passwordError}</p>
      )}

      {/* Success Popup */}
      <div className={success ? "success-popup show" : "success-popup"}>
        <p>{success}</p>
        <button onClick={() => setSuccess(null)}>Close</button>
      </div>

      <form onSubmit={handleSubmit}>
        <label htmlFor="oldPassword">Current Password</label><br />
        <input 
          type="password" 
          className="cp-input" 
          placeholder='Enter your current password'
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          required
        /><br />

        <label htmlFor="newPassword">New Password</label><br />
        <input 
          type="password" 
          className="cp-input" 
          placeholder='Enter your new password'
          value={newPassword}
          onChange={(e) => {
            setNewPassword(e.target.value);
            setPasswordError(""); // Clear password error when new password is changed
          }}
          required
        /><br />

        <label htmlFor="confirmNewPassword">Confirm New Password</label><br />
        <input 
          type="password" 
          className="cp-input" 
          placeholder='Re-enter your new password'
          value={confirmNewPassword}
          onChange={(e) => {
            setConfirmNewPassword(e.target.value);
            setPasswordError(""); // Clear password error when confirm password is changed
          }}
          required
        /><br />
        
        <div className="buttons">
          <button className="primary-btn secondary">
            <Link to="/profile-dashboard">
              Cancel
            </Link>
          </button>
          <button className="primary-btn" type="submit">
            Change Password
          </button>
        </div>
      </form>
    </div>
  );
}
