import './App.css';
import Registration from './Components/LoginSignup/registration';
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import PasswordForgot from './Components/PasswordSetting/passwordForgot';
import EmailSent from './Components/PasswordSetting/emailSent';
import UpdatePassword from './Components/PasswordSetting/updatePassword';
import AdminDashboard from './Components/Dashboard/adminDashboard';
import CRM from './Components/Dashboard/crm';
import CrmReports from './Components/Dashboard/crmReports';
import Questions from './Components/Dashboard/questions';
import CalenderMeeting from './Components/Dashboard/calenderMeeting';
import UserMain from './Components/ProfileManagement/userMain';
import ProfileEdit from './Components/ProfileManagement/profileEdit';
import PasswordEdit from './Components/ProfileManagement/passwordEdit';
import TwoFactorActivation from './Components/ProfileManagement/2FA';
import CalendarBoard from './Components/Calender/calendarBoard';
import CalendarEvents from './Components/Calender/calendarEvents';
import CalendarAvailability from './Components/Calender/calendarAvailability';
import CalendarCommunicate from './Components/Calender/calendarCommunicate';
import CalendarInfo from './Components/Calender/calendarInfo';
import CalendarMeeting from './Components/Calender/calendarMeeting';
import Calender from './Components/Calender/calendar';
import Userform from './Components/userForm/userform';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path='/' element={<Registration />} />
          <Route path='/forgotPassword' element={<PasswordForgot />} />
          <Route path='/emailVerify' element={<EmailSent />} />
          <Route path='/Update-password' element={<UpdatePassword />} />
          <Route path="/userForm" element={<Userform/>} />
          <Route path='/admin-Dashboard' element={<AdminDashboard />}>
            <Route index element={<CrmReports />} />
            <Route path='crm' element={<CRM />} />
            <Route path='crmReports' element={<CrmReports />} />
            <Route path='questions' element={<Questions />} />
            <Route path='calenderMeeting' element={<CalenderMeeting />} />
          </Route>
          <Route path='/profile-dashboard' element={<UserMain />}>
            <Route index element={<ProfileEdit />} />
            <Route path='profile' element={<ProfileEdit />} />
            <Route path='password' element={<PasswordEdit />} />
            <Route path='authentication' element={<TwoFactorActivation />} />
          </Route>
          <Route path='/calendar-board' element={<CalendarBoard />}>
            <Route index element={<Calender />} />
            <Route path='events' element={<CalendarEvents />} />
            <Route path='availability' element={<CalendarAvailability />} />
            <Route path='communicate' element={<CalendarCommunicate />} />
            <Route path='info' element={<CalendarInfo />} />
            <Route path='meetings' element={<CalendarMeeting />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}
export default App;