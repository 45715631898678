import React, { useState } from "react";
import "./passwordSetting.css";
import FpLogo from "../Assets/lockLogo.png";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';

export default function PasswordForgot() {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get(`https://dev-api.ivoz.ai/reset-pass?email=${email}`);
      console.log(response.data);
      
      navigate("/emailVerify");

    } catch (error) {
      console.error("Error sending email:", error);
      if (error.response && error.response.status === 401) {
        navigate("/login"); // Redirect to login page on 401 error
      } else {
        setErrorMessage("Error sending email. Please try again.");
      }
    }
  };

  return (
    <div className="ps-body">
      <div className="fp-container">
      <div className="fp-start">
        <div className="fp-img">
          <img src={FpLogo} alt="lock-icon" />
        </div>
        <div className="fp-heading">Request your Password</div>
        <div className="fp-para">
          <p>
            Enter your email address to forgot your password and click on{" "}
            <span className="color-change">Send Email.</span>{" "}
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <input
            className="fp-input"
            type="email"
            placeholder="Email Address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br></br>
          <div className="buttons">
            <button className="primary-btn secondary">
              <Link to="/" >
                Cancel
              </Link>
            </button>
            <button className="primary-btn" type="submit">
              Send Email
            </button>
          </div>
        </form>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </div>
    </div>
    </div>
  );
}

