import React from "react";
import "./passwordSetting.css";
import emailSent from '../Assets/emailSent.png';
import { Link } from "react-router-dom";

export default function EmailSent() {
  return (
      <div className="ps-body">
        <div className="fp-container">
        <div className="Es-start">
          <div className="fp-img">
            <img src={emailSent} alt="Email-Logo" />
          </div>
          <div className="fp-heading">Email Sent Successfully</div>
          <div className="fp-para"><p>We've sent an email to your email address. It contains instruction to <span className="color-change">update your password.</span> If you don't want to forgot your password, you can choose to <span className="color-change">Log in </span>instead. </p></div>
          
          <button className="primary-btn">
            <Link to="/" className="white">Log In</Link>
          </button>
        </div>
      </div>
      </div>
  );
}
