import React, { useState, useEffect } from "react";
import "./registration.css";
import { Link, useNavigate } from "react-router-dom";

export default function Registration() {
  const [isRightPanelActive, setIsRightPanelActive] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [signUpEmail, setSignUpEmail] = useState("");
  const [signUpPassword, setSignUpPassword] = useState("");
  const [signinEmail, setSigninEmail] = useState("");
  const [signinPassword, setSigninPassword] = useState("");

  // const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [showSignIn, setShowSignIn] = useState(true);
  const [showSignUp, setShowSignUp] = useState(false);
  const [signInError, setSignInError] = useState("");

  // Add a new state to track 2FA block
  const [show2FABlock, setShow2FABlock] = useState(false);

  // Add a new state to store OTP
  const [otp, setOtp] = useState("");

  // Add a new state to store OTP error
  const [otpError, setOtpError] = useState("");
  const [showSMSCodePopup, setShowSMSCodePopup] = useState(false); // State to control SMS code popup visibility
  const [smsReqId, setSMSReqId] = useState("");
  const navigate = useNavigate();
  // SIGNUP INTEGRATION
  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://dev-api.ivoz.ai/signup/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: signUpEmail,
          password: signUpPassword,
          name: firstname,
        }),
      });
      if (response.ok) {
        // Handle success
        console.log("User signed up successfully");
        // setShowSuccessPopup(true);
        alert("Signed up successfully")
      } else {
        // Handle errors
        const errorData = await response.json();
        if (response.status === 422) {
          const errorMessage = errorData.detail && errorData.detail[0].msg;
          if (errorMessage) {
            setPasswordError(errorMessage);
          } else {
            console.error("Error:", errorData.detail);
          }
        } else if (response.status === 400) {
          const errorMessage = errorData.detail;
          if (errorMessage) {
            setPasswordError(errorMessage);
          } else {
            console.error(
              "Error:",
              "Bad Request - Username already registered"
            );
          }
        } else {
          console.error("Failed to sign up", response.statusText);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  // SIGN IN INTEGRATION
  const handleSignInSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://dev-api.ivoz.ai/login/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: signinEmail,
          password: signinPassword,
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        if (
          responseData.status &&
          responseData.description.includes("OTP Has Been Sent")
        ) {
          // Show SMS code popup
          setShowSMSCodePopup(true);
          setSMSReqId(responseData.req_id);
        } else if (
          responseData.message ===
          "2FA Enabled User, Please Log In Using 2FA Route"
        ) {
          // Show 2FA block
          setShow2FABlock(true);
        } else {
          const { access_token } = responseData;
          localStorage.setItem("accessToken", access_token);
          console.log("User signed In successfully");
          console.log("Access Token:", access_token);
          console.log("Response Body:", responseData);
          navigate("/admin-Dashboard");
        }
      } else {
        setSignInError("Incorrect email or password");
      }
    } catch (error) {
      console.error("Error:", error);
      setSignInError("Error occurred while signing in");
    }
  };

  // Handle SMS submit
  const handleSMSSubmit = async (otp) => {
    try {
      const response = await fetch("https://dev-api.ivoz.ai/login-sms-2fa/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          req_id: smsReqId,
          otp: parseInt(otp),
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        const { access_token } = responseData;
        localStorage.setItem("accessToken", access_token);
        console.log("User signed In successfully after SMS OTP");
        console.log("Access Token:", access_token);
        navigate("/admin-Dashboard");
      } else {
        console.error("Failed to verify SMS OTP:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Handle OTP submit
  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://dev-api.ivoz.ai/login-2fa/", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: signinEmail,
          password: signinPassword,
          auth_code: parseInt(otp), // Convert OTP to integer
        }),
      });

      if (response.ok) {
        const responseData = await response.json();
        const { access_token } = responseData;
        localStorage.setItem("accessToken", access_token);
        console.log("User signed In successfully after 2FA");
        console.log("Access Token:", access_token);
        navigate("/admin-Dashboard");
      } else {
        const errorData = await response.json(); // Parse error response
        setOtpError(errorData.message || "Incorrect OTP"); // Set error message from server or default
      }
    } catch (error) {
      console.error("Error:", error);
      setOtpError("Error occurred while verifying OTP");
    }
  };

  // RESPONSIVE SIGNIN SIGNUp
  const handleClick = () => {
    setShowSignIn(!showSignIn);
    setShowSignUp(!showSignUp); // Toggle sign-up form visibility
  };

  useEffect(() => {
    const signUpButton = document.getElementById("signUp");
    const signInButton = document.getElementById("signIn");
    const mainSheet = document.getElementById("main");

    signUpButton.addEventListener("click", () => {
      mainSheet.classList.add("right-panel-active");
      setIsRightPanelActive(true);
      setShowSignUp(true);
    });

    signInButton.addEventListener("click", () => {
      mainSheet.classList.remove("right-panel-active");
      setIsRightPanelActive(false);
      setShowSignUp(false);
    });

    return () => {
      signUpButton.removeEventListener("click", () => {
        mainSheet.classList.add("right-panel-active");
        setIsRightPanelActive(true);
      });

      signInButton.removeEventListener("click", () => {
        mainSheet.classList.remove("right-panel-active");
        setIsRightPanelActive(false);
      });
    };
  }, []);

  return (
    <div className="body loginBody">
      <div
        className={`container ${
          isRightPanelActive ? "right-panel-active" : ""
        } ${show2FABlock || showSMSCodePopup ? "twofa-active" : ""}`}
        id="main"
      >
        {/* {showSuccessPopup && (
          <div className="signup-success">
            <h2>Success!</h2>
            <p>You have successfully signed up.</p>
            <button onClick={closeSuccessPopup}>Close</button>
          </div>
        )} */}
        <div
          className="sign-in"
          style={{ display: showSignIn ? "block" : "none" }}
        >
          <form
            className="registration-form"
            method="post"
            onSubmit={handleSignInSubmit}
          >
            <h1>Sign In</h1>
            <input
              className="registrationPage"
              type="email"
              placeholder="Email"
              value={signinEmail}
              onChange={(e) => setSigninEmail(e.target.value)}
              required
            />
            <input
              className="registrationPage"
              type="password"
              placeholder="Password"
              value={signinPassword}
              onChange={(e) => setSigninPassword(e.target.value)}
              required
            />
            {signInError && <p className="error-message">{signInError}</p>}
            <div className="forgot-password-btn">
              <Link to="/forgotPassword" className="fp-navigate">
                Forgot Password
              </Link>
            </div>
            <button type="submit" className="signin_btn">
              Sign In
            </button>
            <div className="create-acc-btn">
              <button className="signToggle" onClick={handleClick}>
                create an account
              </button>
            </div>
          </form>
        </div>

        <div
          className="sign-up"
          style={{ display: showSignUp ? "block" : "none" }}
          onSubmit={handleSignupSubmit}
        >
          <form className="registration-form" method="post">
            <h1>Create Account</h1>
            <input
              className="registrationPage"
              type="text"
              placeholder="Full Name"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              required
            />
            <input
              className="registrationPage"
              type="email"
              placeholder="Email"
              value={signUpEmail}
              onChange={(e) => setSignUpEmail(e.target.value)}
              required
            />
            <input
              className="registrationPage"
              type="password"
              placeholder="Password"
              value={signUpPassword}
              onChange={(e) => {
                setSignUpPassword(e.target.value);
                setPasswordError(""); // Clear password error when password is changed
              }}
              required
            />
            {/* Display password error message */}
            {passwordError && (
              <p className="signup-error-message">{passwordError}</p>
            )}
            <button type="submit" className="signup_btn">
              Sign Up
            </button>
            <div className="create-acc-btn">
              <button className="signToggle" onClick={handleClick}>
                Back to Sign In
              </button>
            </div>
          </form>
        </div>

        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-left">
              <h1>Welcome Back</h1>
              <p>
                To keep connect with us please login with your personal info
              </p>
              <button id="signIn">Sign In</button>
            </div>
            <div className="overlay-right">
              <h1>Welcome to iVoz Ai</h1>
              <p>Enter your personal details and start journey with us</p>
              <button id="signUp">Sign Up</button>
            </div>
          </div>
        </div>
      </div>
      {show2FABlock && (
        <div className="twofa-block">
          <div
            className="back-to-login"
            onClick={() => setShow2FABlock(false)}
          >
            <i className="fa-solid fa-xmark" />
          </div>
          <h1>Enter OTP</h1>
          <form onSubmit={handleOTPSubmit}>
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
            {otpError && <p className="error-message">{otpError}</p>}
            <button type="submit">Verify OTP</button>
          </form>
        </div>
      )}

      {/* SMS code popup */}
      {showSMSCodePopup && (
        <div className="sms-code-popup twofa-block">
          <div
            className="back-to-login"
            onClick={() => setShowSMSCodePopup(false)}
          >
            <i className="fa-solid fa-xmark" />
          </div>
          <h1>Enter SMS Code</h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSMSSubmit(otp);
            }}
          >
            <input
              type="text"
              placeholder="Enter SMS Code"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
            <button type="submit">Verify</button>
          </form>
        </div>
      )}
    </div>
  );
}

// import React, { useState, useEffect } from "react";
// import "./registration.css";
// import { Link, useNavigate } from "react-router-dom";

// export default function Registration() {
//   const [isRightPanelActive, setIsRightPanelActive] = useState(false);

//   const [firstname, setFirstname] = useState("");
//   const [signUpEmail, setSignUpEmail] = useState("");
//   const [signUpPassword, setSignUpPassword] = useState("");
//   const [signinEmail, setSigninEmail] = useState("");
//   const [signinPassword, setSigninPassword] = useState("");

//   const [showSuccessPopup, setShowSuccessPopup] = useState(false);

//   const [passwordError, setPasswordError] = useState("");
//   const [showSignIn, setShowSignIn] = useState(true);
//   const [showSignUp, setShowSignUp] = useState(false);

//   const [signInError, setSignInError] = useState("");

//   // Add a new state to track 2FA block
//   const [show2FABlock, setShow2FABlock] = useState(false);

//   // Add a new state to store OTP
//   const [otp, setOtp] = useState("");

//   // Add a new state to store OTP error
//   const [otpError, setOtpError] = useState("");

//   const [showSMSCodePopup, setShowSMSCodePopup] = useState(false); // State to control SMS code popup visibility
//   const [smsReqId, setSMSReqId] = useState("");

//   const navigate = useNavigate();

//   // SIGNUP INTEGRATION
//   const handleSignupSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch("https://dev-api.ivoz.ai/signup/", {
//         method: "POST",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           email: signUpEmail,
//           password: signUpPassword,
//           name: firstname,
//         }),
//       });
//       if (response.ok) {
//         // Handle success
//         console.log("User signed up successfully");
//         setShowSuccessPopup(true);
//       } else {
//         // Handle errors
//         const errorData = await response.json();
//         if (response.status === 422) {
//           const errorMessage = errorData.detail && errorData.detail[0].msg;
//           if (errorMessage) {
//             setPasswordError(errorMessage);
//           } else {
//             console.error("Error:", errorData.detail);
//           }
//         } else if (response.status === 400) {
//           const errorMessage = errorData.detail;
//           if (errorMessage) {
//             setPasswordError(errorMessage);
//           } else {
//             console.error(
//               "Error:",
//               "Bad Request - Username already registered"
//             );
//           }
//         } else {
//           console.error("Failed to sign up", response.statusText);
//         }
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   // SIGNUP SUCCESSFULL CLOSE BUTTON
//   const closeSuccessPopup = () => {
//     setShowSuccessPopup(false);
//     navigate("/");
//     setTimeout(() => {
//       window.location.reload();
//     }, 2000);
//   };

//   // SIGN IN INTEGRATION
//   const handleSignInSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch("https://dev-api.ivoz.ai/login/", {
//         method: "POST",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           email: signinEmail,
//           password: signinPassword,
//         }),
//       });

//       if (response.ok) {
//         const responseData = await response.json();
//         if (
//           responseData.status &&
//           responseData.description.includes("OTP Has Been Sent")
//         ) {
//           // Show SMS code popup
//           setShowSMSCodePopup(true);
//           setSMSReqId(responseData.req_id);
//         } else if (
//           responseData.message ===
//           "2FA Enabled User, Please Log In Using 2FA Route"
//         ) {
//           // Show 2FA block
//           setShow2FABlock(true);
//         } else {
//           const { access_token } = responseData;
//           sessionStorage.setItem("accessToken", access_token);
//           console.log("User signed In successfully");
//           console.log("Access Token:", access_token);
//           console.log("Response Body:", responseData);
//           navigate("/admin-Dashboard");
//         }
//       } else {
//         setSignInError("Incorrect email or password");
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       setSignInError("Error occurred while signing in");
//     }
//   };

//   // Handle SMS submit
//   const handleSMSSubmit = async (otp) => {
//     try {
//       const response = await fetch("https://dev-api.ivoz.ai/login-sms-2fa/", {
//         method: "POST",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           req_id: smsReqId,
//           otp: parseInt(otp),
//         }),
//       });

//       if (response.ok) {
//         const responseData = await response.json();
//         const { access_token } = responseData;
//         sessionStorage.setItem("accessToken", access_token);
//         console.log("User signed In successfully after SMS OTP");
//         console.log("Access Token:", access_token);
//         navigate("/admin-Dashboard");
//       } else {
//         console.error("Failed to verify SMS OTP:", response.statusText);
//       }
//     } catch (error) {
//       console.error("Error:", error);
//     }
//   };

//   // Handle OTP submit
//   const handleOTPSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await fetch("https://dev-api.ivoz.ai/login-2fa/", {
//         method: "POST",
//         headers: {
//           Accept: "application/json",
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           email: signinEmail,
//           password: signinPassword,
//           auth_code: parseInt(otp), // Convert OTP to integer
//         }),
//       });

//       if (response.ok) {
//         const responseData = await response.json();
//         const { access_token } = responseData;
//         sessionStorage.setItem("accessToken", access_token);
//         console.log("User signed In successfully after 2FA");
//         console.log("Access Token:", access_token);
//         navigate("/admin-Dashboard");
//       } else {
//         const errorData = await response.json(); // Parse error response
//         setOtpError(errorData.message || "Incorrect OTP"); // Set error message from server or default
//       }
//     } catch (error) {
//       console.error("Error:", error);
//       setOtpError("Error occurred while verifying OTP");
//     }
//   };

//   // RESPONSIVE SIGNIN SIGNUp
//   const handleClick = () => {
//     setShowSignIn(!showSignIn);
//     setShowSignUp(!showSignUp); // Toggle sign-up form visibility
//   };

//   useEffect(() => {
//     const signUpButton = document.getElementById("signUp");
//     const signInButton = document.getElementById("signIn");
//     const mainSheet = document.getElementById("main");

//     signUpButton.addEventListener("click", () => {
//       mainSheet.classList.add("right-panel-active");
//       setIsRightPanelActive(true);
//       setShowSignUp(true);
//     });

//     signInButton.addEventListener("click", () => {
//       mainSheet.classList.remove("right-panel-active");
//       setIsRightPanelActive(false);
//       setShowSignUp(false);
//     });

//     return () => {
//       signUpButton.removeEventListener("click", () => {
//         mainSheet.classList.add("right-panel-active");
//         setIsRightPanelActive(true);
//       });

//       signInButton.removeEventListener("click", () => {
//         mainSheet.classList.remove("right-panel-active");
//         setIsRightPanelActive(false);
//       });
//     };
//   }, []);

//   return (
//     <div className="body loginBody">
//       <div
//         className={`container ${
//           isRightPanelActive ? "right-panel-active" : ""
//         } ${show2FABlock || showSMSCodePopup ? "twofa-active" : ""}`}
//         id="main"
//       >
//         {showSuccessPopup && (
//           <div className="signup-success">
//             <h2>Success!</h2>
//             <p>You have successfully signed up.</p>
//             <button onClick={closeSuccessPopup}>Close</button>
//           </div>
//         )}
//         <div
//           className="sign-in"
//           style={{ display: showSignIn ? "block" : "none" }}
//         >
//           <form
//             className="registration-form"
//             method="post"
//             onSubmit={handleSignInSubmit}
//           >
//             <h1>Sign In</h1>
//             <input
//               className="registrationPage"
//               type="email"
//               placeholder="Email"
//               value={signinEmail}
//               onChange={(e) => setSigninEmail(e.target.value)}
//               required
//             />
//             <input
//               className="registrationPage"
//               type="password"
//               placeholder="Password"
//               value={signinPassword}
//               onChange={(e) => setSigninPassword(e.target.value)}
//               required
//             />
//             {signInError && <p className="error-message">{signInError}</p>}
//             <div className="forgot-password-btn">
//               <Link to="/forgotPassword" className="fp-navigate">
//                 Forgot Password
//               </Link>
//             </div>
//             <button type="submit" className="signin_btn">
//               Sign In
//             </button>
//             <div className="create-acc-btn">
//               <button className="signToggle" onClick={handleClick}>
//                 create an account
//               </button>
//             </div>
//           </form>
//         </div>

//         <div
//           className="sign-up"
//           style={{ display: showSignUp ? "block" : "none" }}
//           onSubmit={handleSignupSubmit}
//         >
//           <form className="registration-form" method="post">
//             <h1>Create Account</h1>
//             <input
//               className="registrationPage"
//               type="text"
//               placeholder="Name"
//               value={firstname}
//               onChange={(e) => setFirstname(e.target.value)}
//               required
//             />
//             <input
//               className="registrationPage"
//               type="email"
//               placeholder="Email"
//               value={signUpEmail}
//               onChange={(e) => setSignUpEmail(e.target.value)}
//               required
//             />
//             <input
//               className="registrationPage"
//               type="password"
//               placeholder="Password"
//               value={signUpPassword}
//               onChange={(e) => {
//                 setSignUpPassword(e.target.value);
//                 setPasswordError(""); // Clear password error when password is changed
//               }}
//               required
//             />
//             {/* Display password error message */}
//             {passwordError && (
//               <p className="signup-error-message">{passwordError}</p>
//             )}
//             <button type="submit" className="signup_btn">
//               Sign Up
//             </button>
//             <div className="create-acc-btn">
//               <button className="signToggle" onClick={handleClick}>
//                 Back to Sign In
//               </button>
//             </div>
//           </form>
//         </div>

//         <div className="overlay-container">
//           <div className="overlay">
//             <div className="overlay-left">
//               <h1>Welcome Back</h1>
//               <p>
//                 To keep connect with us please login with your personal info
//               </p>
//               <button id="signIn">Sign In</button>
//             </div>
//             <div className="overlay-right">
//               <h1>Hello Friends</h1>
//               <p>Enter your personal details and start journey with us</p>
//               <button id="signUp">Sign Up</button>
//             </div>
//           </div>
//         </div>
//       </div>
//       {show2FABlock && (
//         <div className="twofa-block">
//           <h1>Enter OTP</h1>
//           <form onSubmit={handleOTPSubmit}>
//             <input
//               type="text"
//               placeholder="Enter OTP"
//               value={otp}
//               onChange={(e) => setOtp(e.target.value)}
//               required
//             />
//             {otpError && <p className="error-message">{otpError}</p>}
//             <button type="submit">Verify OTP</button>
//           </form>
//         </div>
//       )}

//       {/* SMS code popup */}
//       {showSMSCodePopup && (
//         <div className="sms-code-popup twofa-block">
//           <h1>Enter SMS Code</h1>
//           <form
//             onSubmit={(e) => {
//               e.preventDefault();
//               handleSMSSubmit(otp);
//             }}
//           >
//             <input
//               type="text"
//               placeholder="Enter SMS Code"
//               value={otp}
//               onChange={(e) => setOtp(e.target.value)}
//               required
//             />
//             <button type="submit">Verify</button>
//           </form>
//         </div>
//       )}
//     </div>
//   );
// }
