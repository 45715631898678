import React, { useState } from "react";
import "./passwordSetting.css";
import { useNavigate } from "react-router-dom";

export default function UpdatePassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  // Extract token from URL
  const urlParams = new URLSearchParams(window.location.hash.split("?")[1]);
  const token = urlParams.get("reset_token");
  console.log("token", token);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmNewPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const response = await fetch("https://dev-api.ivoz.ai/set-password", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          new_password: newPassword,
        }),
      });

      const data = await response.json();

      console.log("API Response Status:", response.status);
      console.log("API Response Data:", data);

      if (response.ok) {
        console.log("Password updated successfully:", data.message);
        alert(
          "Password Updated Successfully. You can check your mail to verify."
        );
        setSuccess(data.message); // Set the success message
        setNewPassword("");
        setConfirmNewPassword("");
      } else {
        if (
          response.status === 401 &&
          data.detail === "nvalid or expired token"
        ) {
          navigate("/");
        } else if (
          data.detail === "New password can not be same as old password"
        ) {
          alert("Error: New password cannot be the same as the old password.");
        } else {
          setError(data.detail[0].msg || "Failed to update password");
        }
      }
    } catch (error) {
      console.error("Error updating password:", error);
      setError("Error updating password");
    }
  };

  return (
    <div className="ps-body">
      <div className="fp-container">
        <div className="fp-start">
          <div className="fp-heading">Update Your Password</div>
          <form className="up-form" onSubmit={handleSubmit}>
            <label>New Password</label>
            <br />
            <input
              className="fp-input"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            {error &&
              error.includes("String should have at least 8 characters") && (
                <div className="error-message">{error}</div>
              )}
            {error &&
              error.includes(
                "Password must contain at least one uppercase letter"
              ) && <div className="error-message">{error}</div>}
            {error &&
              error.includes(
                "Password must contain at least one special character"
              ) && <div className="error-message">{error}</div>}
            <br />
            <label>Confirm New Password</label>
            <br />
            <input
              className="fp-input"
              type="password"
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
              required
            />
            {error && error.includes("Passwords do not match") && (
              <div className="error-message">{error}</div>
            )}
            <br />
            {success && <div className="success-message">{success}</div>}

            <div className="up-btn">
              <button type="submit" className="primary-btn">
                Update Password
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
