import React from "react";
import './calendar.css';
import { Link } from "react-router-dom";

export default function CalendarEvents() {
  return (
    <>
      <div className="calendar-head">
        <h2>Event Details</h2>
        <div className="calendar-icon">
         <Link to="/calendar-board"><i className="fa-solid fa-xmark"></i></Link> 
        </div>
      </div>
      <hr />

      <div className="event-form">
        <form action="">
          <label htmlFor="EventName">Event Name*</label>
          <br />
          <input type="text" />
          <br /><br />

          <label htmlFor="Duration">Duration*</label>
          <br />
          <div className="custom-select">
            <select name="category" id="category">
              <option value="30">30 min</option>
              <option value="15">15 min</option>
              <option value="60">1 Hour</option>
            </select>
          </div>
          <br /><br />

          <label htmlFor="Location">Location*</label>
          <br />
          <div className="location-inner">
            <div className="zoom-opt">
              <i className="fa-solid fa-video"></i>
              <p>Zoom</p>
            </div>
            <div className="phone-opt">
              <i className="fa-solid fa-phone"></i>
              <p>Phone Call</p>
            </div>
            <div className="person-opt">
              <i className="fa-solid fa-location-dot"></i>
              <p>In-person</p>
            </div>
          </div>
          <br /><br />

          <label htmlFor="Description">Description</label>
          <br />
          <textarea
            name="description"
            id="description"
            rows="4"
            cols="50"
            placeholder="Enter a description"
          ></textarea>

          <br />
        </form>


      </div>

      <div className="calender-btns">
          <button className="primary-btn secondary">Cancel</button>
          <button className="primary-btn">Save and close</button>
        </div>
    </>
  );
}
