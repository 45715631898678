import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const TwoFactorActivation = () => {
  const navigate = useNavigate();
  const [qrCode, setQRCode] = useState("");
  const [secretKey, setSecretKey] = useState("");
  const [otp, setOTP] = useState("");
  const [smsOtp, setSmsOTP] = useState("");
  const [showQRCode, setShowQRCode] = useState(false);
  const [showSmsForm, setShowSmsForm] = useState(false);
  const [isAuthenticatorChecked, setIsAuthenticatorChecked] = useState(false);
  const [isSmsChecked, setIsSmsChecked] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const check2FAStatus = async () => {
      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        setError("Access token not found");
        return;
      }

      try {
        const response = await axios.get(
          "https://dev-api.ivoz.ai/user/activate-2fa",
          {
            headers: {
              accept: "application/json",
              token: accessToken,
            },
          }
        );

        if (response.data.detail === "Authenticator Already Connected") {
          setIsAuthenticatorChecked(true);
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          navigate("/");
        } else {
          setError("Failed to fetch authenticator status");
          console.error(err);
        }
      }

      try {
        const response = await axios.post(
          "https://dev-api.ivoz.ai/user/activate-sms-2fa",
          {},
          {
            headers: {
              accept: "application/json",
              token: accessToken,
            },
          }
        );

        if (response.data.detail === "SMS 2FA Already Active") {
          setIsSmsChecked(true);
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          if (err.response.data.detail === "SMS 2FA Already Active") {
            setIsSmsChecked(true);
          } else {
            navigate("/");
          }
        } else {
          setError("Failed to fetch SMS 2FA status");
          console.error(err);
        }
      }
    };

    check2FAStatus();
  }, [navigate]);

  const handleCheckboxChange = async (event, type) => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      setError("Access token not found");
      return;
    }

    if (event.target.checked) {
      try {
        if (type === "authenticator") {
          const response = await axios.get(
            "https://dev-api.ivoz.ai/user/activate-2fa",
            {
              headers: {
                accept: "application/json",
                token: accessToken,
              },
            }
          );

          setQRCode(response.data.qr_code);
          setSecretKey(response.data.secret_key);
          setShowQRCode(true);
          setShowSmsForm(false);
        } else if (type === "sms") {
          const response = await axios.post(
            "https://dev-api.ivoz.ai/user/activate-sms-2fa",
            {},
            {
              headers: {
                accept: "application/json",
                token: accessToken,
              },
            }
          );

          if (response.data.detail !== "SMS 2FA Already Active") {
            setShowSmsForm(true);
          }
          setShowQRCode(false);
        }

        setError("");
      } catch (err) {
        if (err.response) {
          if (err.response.status === 401) {
            if (err.response.data.detail === "Mobile Number Missing From Profile. Kindly Update The Mobile Number First") {
              setError("Mobile Number Missing From Profile. Kindly Update The Mobile Number First");
            } else {
              navigate("/");
            }
          } else {
            setError("Failed to fetch 2FA setup information");
          }
        } else {
          setError("Failed to fetch 2FA setup information");
        }
        console.error(err);
      }
    } else {
      try {
        if (type === "authenticator") {
          const response = await axios.get(
            "https://dev-api.ivoz.ai/user/disable-2fa",
            {
              headers: {
                accept: "application/json",
                token: accessToken,
              },
            }
          );

          if (response.data.status) {
            setIsAuthenticatorChecked(false);
            setShowQRCode(false);
            setError(""); // Clear any previous errors
            alert(response.data.message);
          }
        } else if (type === "sms") {
          const response = await axios.get(
            "https://dev-api.ivoz.ai/user/disable-sms-2fa",
            {
              headers: {
                accept: "application/json",
                token: accessToken,
              },
            }
          );

          if (response.data.status) {
            setIsSmsChecked(false);
            setShowSmsForm(false);
            setError(""); // Clear any previous errors
            alert(response.data.message);
          }
        }
      } catch (err) {
        if (err.response) {
          if (err.response.status === 401) {
            navigate("/");
          } else {
            setError("Failed to disable 2FA");
          }
        } else {
          setError("Failed to disable 2FA");
        }
        console.error(err);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      setError("Access token not found");
      return;
    }

    try {
      if (showQRCode) {
        const response = await axios.post(
          "https://dev-api.ivoz.ai/user/activate-2fa",
          {
            secret_key: secretKey,
            otp: otp,
          },
          {
            headers: {
              accept: "application/json",
              token: accessToken,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          alert("2FA activated successfully!");
          setError(""); // Clear any previous errors
        }
      } else if (showSmsForm) {
        const response = await axios.post(
          "https://dev-api.ivoz.ai/user/verify-sms-2fa",
          {
            otp: smsOtp,
          },
          {
            headers: {
              accept: "application/json",
              token: accessToken,
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          alert("SMS 2FA activated successfully!");
          setError(""); // Clear any previous errors
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          if (err.response.data.detail === "Mobile Number Missing From Profile. Kindly Update The Mobile Number First") {
            setError("Mobile Number Missing From Profile. Kindly Update The Mobile Number First");
          } else {
            navigate("/");
          }
        } else {
          setError("Failed to activate 2FA");
        }
      } else {
        setError("Failed to activate 2FA");
      }
      console.error(err);
    }
  };

  return (
    <>
      <div className="two-fa">
        <div className="two-fa-heading">
          <div className="two-fa-head">
            <h2>Set up Authentication app</h2>
            <p>
              Use a phone app like Microsoft Authenticator or Google
              Authenticator, etc. to get two factor authentication codes.
            </p>
          </div>
          <div className="two-fa-toggle">
            <input
              type="checkbox"
              id="twoFaCheck"
              checked={isAuthenticatorChecked}
              onChange={(event) => handleCheckboxChange(event, "authenticator")}
            />
            <label htmlFor="twoFaCheck" className="twoFaCheckButton"></label>
          </div>
        </div>
        <hr />
        {showQRCode && (
          <div className="showToggle">
            <h4>Scan QR Code below</h4>
            <img src={qrCode} alt="QR Code" />
            <form onSubmit={handleSubmit}>
              <div className="otp2fa">
                <label htmlFor="otp">
                  Verify code from your authenticator app
                </label>
                <br />
                <input
                  type="text"
                  id="otp"
                  value={otp}
                  placeholder="Enter your 6 digit code here"
                  onChange={(e) => setOTP(e.target.value)}
                  required
                />
              </div>
              <div className="codeConfirmButton">
                <button type="submit">Submit</button>
              </div>
            </form>
            <hr />
          </div>
        )}
        {error && <div className="error">{error}</div>}

        <div className="sms-fa">
          <h5>Alternative 2FA Authenticator</h5>
          <div className="two-fa-heading">
            <div className="two-fa-head">
              <h2>SMS Authentication</h2>
              <p>
                Get one-time codes sent to your phone via SMS to complete
                authentication requests
              </p>
            </div>
            <div className="two-fa-toggle">
              <input
                type="checkbox"
                id="smsFaCheck"
                checked={isSmsChecked}
                onChange={(event) => handleCheckboxChange(event, "sms")}
              />
              <label htmlFor="smsFaCheck" className="smsFaCheckButton"></label>
            </div>
          </div>
          {showSmsForm && (
            <div className="showsmsToggle">
              <form onSubmit={handleSubmit}>
                <div className="otpsms2fa">
                  <label htmlFor="smsotp">Verify code</label>
                  <br />
                  <input
                    type="text"
                    id="smsotp"
                    value={smsOtp}
                    placeholder="Enter your 6 digit code here"
                    onChange={(e) => setSmsOTP(e.target.value)}
                    required
                  />
                </div>
                <div className="smscodeConfirmButton">
                  <button type="submit">Submit</button>
                </div>
              </form>
              <hr />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TwoFactorActivation;
