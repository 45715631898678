import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./userMain.css";
import userProfile from "../Assets/userProfile.png";

export default function ProfileEdit() {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    email: "example@example.com",
    phone: "",
    country: "",
    city: "",
    zipcode: "",
  });

  const [isEditMode, setIsEditMode] = useState(false);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
          console.error("Access token not found");
          navigate("/");
          return;
        }
        const response = await fetch("https://dev-api.ivoz.ai/user/", {
          method: "GET",
          headers: {
            accept: "application/json",
            token: accessToken,
          },
        });
        if (response.status === 401) {
          console.error("Unauthorized access");
          navigate("/");
          return;
        }
        const data = await response.json();
        setUserDetails(data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    };

    fetchUserDetails();
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleEditClick = () => {
    setIsEditMode(true);
  };

  const handleSaveClick = async () => {
    try {
      if (
        !userDetails.first_name ||
        !userDetails.last_name ||
        !userDetails.phone ||
        !userDetails.country ||
        !userDetails.city ||
        !userDetails.zipcode
      ) {
        alert("Please fill in all the details");
        return;
      }

      const accessToken = localStorage.getItem("accessToken");

      if (!accessToken) {
        console.error("Access token not found");
        navigate("/");
        return;
      }
      const response = await fetch("https://dev-api.ivoz.ai/user/", {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          token: accessToken,
        },
        body: JSON.stringify({
          first_name: userDetails.first_name,
          last_name: userDetails.last_name,
          phone: userDetails.phone,
          city: userDetails.city,
          country: userDetails.country,
          zipcode: userDetails.zipcode,
        }),
      });

      const data = await response.json();

      if (response.ok) {
        setIsEditMode(false);
        console.log("User details updated successfully:", data);
        alert("Your details updated successfully");
      } else {
        console.error("Error updating user details:", data);
      }
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };

  const countries = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Côte d'Ivoire",
    "Cabo Verde",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Comoros",
    "Congo (Congo-Brazzaville)",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czechia (Czech Republic)",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini (fmr. 'Swaziland')",
    "Ethiopia",
    "Fiji",
    "Finland",
    "France",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Greece",
    "Grenada",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Holy See",
    "Honduras",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Morocco",
    "Mozambique",
    "Myanmar (formerly Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "North Korea",
    "North Macedonia",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestine State",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Qatar",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Vincent and the Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Sweden",
    "Switzerland",
    "Syria",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States of America",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Vietnam",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  return (
    <>
      <div className="upper_right">
        <h1 className="right_heading">Personal Information</h1>
      </div>
      <hr />
      <div className="img_wrapper">
        <div className="img">
          <img src={userProfile} alt="usersProfile" />
        </div>
      </div>
      <hr />
      <form className="profile-up" action="#">
        <div className="form-sec">
          <label htmlFor="first_name">First Name</label>
          <input
            className={`pu-input ${isEditMode ? "editable" : "nonEditable"}`}
            type="text"
            name="first_name"
            placeholder="Enter your first name"
            value={userDetails.first_name || ""}
            onChange={handleChange}
            disabled={!isEditMode}
          />
        </div>
        <div className="form-sec">
          <label htmlFor="last_name">Last Name</label>
          <input
            className={`pu-input ${isEditMode ? "editable" : "nonEditable"}`}
            type="text"
            name="last_name"
            placeholder="Enter your last name"
            value={userDetails.last_name || ""}
            onChange={handleChange}
            disabled={!isEditMode}
          />
        </div>
        <div className="form-sec">
          <label htmlFor="email">Email Address</label>
          <input
            className="pu-input nonEditable"
            type="email"
            name="email"
            placeholder="Enter your email address"
            value={userDetails.email || ""}
            disabled={true}
          />
        </div>
        <div className="form-sec">
          <label htmlFor="phone">Phone Number</label>
          <input
            className={`pu-input ${isEditMode ? "editable" : "nonEditable"}`}
            type="text"
            name="phone"
            placeholder="+91-123456789"
            value={userDetails.phone || ""}
            onChange={handleChange}
            disabled={!isEditMode}
          />
        </div>
        <div className="form-sec">
          <label htmlFor="country">Country</label>
          <select
            className={`pu-input ${isEditMode ? "editable" : "nonEditable"}`}
            name="country"
            value={userDetails.country || ""}
            onChange={handleChange}
            disabled={!isEditMode}
          >
            {countries.map((country) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </select>
        </div>
        <div className="form-sec">
          <label htmlFor="city">City</label>
          <input
            className={`pu-input ${isEditMode ? "editable" : "nonEditable"}`}
            type="text"
            name="city"
            placeholder="Enter your city name"
            value={userDetails.city || ""}
            onChange={handleChange}
            disabled={!isEditMode}
          />
        </div>
        <div className="form-sec">
          <label htmlFor="zipcode">Zip Code</label>
          <input
            className={`pu-input ${isEditMode ? "editable" : "nonEditable"}`}
            type="text"
            name="zipcode"
            placeholder="Enter your zip code"
            value={userDetails.zipcode || ""}
            onChange={handleChange}
            disabled={!isEditMode}
          />
        </div>
      </form>

      <hr />
      {!isEditMode ? (
        <button className="edit_profile" onClick={handleEditClick}>
          Edit Profile
        </button>
      ) : (
        <button className="edit_profile" onClick={handleSaveClick}>
          Save
        </button>
      )}
    </>
  );
}
