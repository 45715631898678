import React, { useState } from "react";
import "./calendar.css";
import { Link } from "react-router-dom";

export default function CalendarMeeting() {
  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <div className="calendar-head">
        <h2>Meeting Settings</h2>
        <div className="calendar-icon">
          <Link to="/calendar-board">
            <i className="fa-solid fa-xmark"></i>
          </Link>
        </div>
      </div>
      <hr />
      <div className="event-form">
        <h3>Data Range</h3>
        <p>Invite can schedule....</p>
        <div className="input-checkbox">
          <label className="meeting-label">
            <input
              className="check"
              type="radio"
              name="option"
              value="Option 1"
              checked={selectedOption === "Option 1"}
              onChange={handleOptionChange}
            />{" "}
            <input className="checkbox-text" type="text" value={60} />
            <select className="checkbox-select" name="category" id="category">
              <option value="calender">Calendar Days</option>
              <option value="week">Week Days</option>
            </select>
          </label>
          <br />
          <label className="meeting-label">
            <input
              className="check"
              type="radio"
              name="option"
              value="Option 2"
              checked={selectedOption === "Option 2"}
              onChange={handleOptionChange}
            />{" "}
            Within a day range
          </label>
          <br />
          <label className="meeting-label">
            <input
              className="check"
              type="radio"
              name="option"
              value="Option 3"
              checked={selectedOption === "Option 3"}
              onChange={handleOptionChange}
            />{" "}
            Indefinitely into the future
          </label>
          <br />
        </div>
        <div className="available_hr">
          <h3>Available hours</h3>
          <p>Customize regular availability or add date specific exceptions</p>
          <hr />
        </div>
        <div className="calender-flex">
          <div className="cf-left">
            <h3>Event Limits</h3>
            <div className="cf-innerContent">
              <div className="cf-inrHead">
                <div className="inrFlex">
                  <h4 className="cf_innerhead">Buffer time</h4>
                  <p className="cf-content">
                    Add time before or after booked events.
                  </p>
                </div>
                <p className="cf-inrRight">None</p>
              </div>
            </div>
            <div className="cf-innerContent">
              <div className="cf-inrHead">
                <div className="inrFlex">
                  <h4 className="cf_innerhead">Minimum notice</h4>
                  <p className="cf-content">
                    Set the minimum amount of notice that is required.
                  </p>
                </div>
                <p className="cf-inrRight">4 hours</p>
              </div>
            </div>
            <div className="cf-innerContent">
              <div className="cf-inrHead">
                <div className="inrFlex">
                  <h4 className="cf_innerhead">Daily limit</h4>
                  <p className="cf-content">
                    See the maximum events allowed per day.
                  </p>
                </div>
                <p className="cf-inrRight">Not set</p>
              </div>
            </div>
          </div>
        </div>
        <div className="calender-btns">
          <button className="primary-btn secondary">Cancel</button>
          <button className="primary-btn">Save and close</button>
        </div>
      </div>
    </>
  );
}
