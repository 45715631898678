// import React, { useState, useEffect, useCallback } from "react";
// import "./calendar.css";
// import Calendar from "react-calendar";
// import { useNavigate } from "react-router-dom";

// export default function CalendarComponent() {
//   const [date, setDate] = useState(new Date());
//   const navigate = useNavigate();
//   const [events, setEvents] = useState([]);
//   const [selectedEvent, setSelectedEvent] = useState(null); 
//   const fetchEvents = useCallback(async () => {
//     try {
//       const token = localStorage.getItem("accessToken");

//       // Check if token exists
//       if (!token) {
//         console.error("Access token not found");
//         return;
//       }
//       const response = await fetch(
//         "https://dev-api.ivoz.ai/google-calender/view-events?start_time=2024-05-07&end_time=2024-05-14",
//         {
//           headers: {
//             accept: "application/json",
//             token: token, // Pass token in the headers
//           },
//         }
//       );
//       if (response.status === 401) {
//         navigate("/");
//         return;
//       }

//       if (!response.ok) {
//         throw new Error("Failed to fetch events");
//       }

//       const data = await response.json();
//       console.log("API Response:", data);

//       if (!Array.isArray(data)) {
//         throw new Error("Fetched data is not an array");
//       }

//       setEvents(data);
//     } catch (error) {
//       console.error("Error fetching events:", error);
//     }
//   }, [navigate]);

//   useEffect(() => {
//     fetchEvents();
//   }, [fetchEvents]);
//   const eventDates = events.map((event) =>
//     new Date(event.start.dateTime).toDateString()
//   );

//   const eventClassName = ({ date }) => {
//     const dateString = date.toDateString();
//     return eventDates.includes(dateString) ? "event-date" : null;
//   };

//   const handleClickDay = (value) => {
//     // Extract the date part from the selected date and the event dates
//     const clickedDate = value.toDateString();
//     const clickedEvent = events.find(
//       (event) => new Date(event.start.dateTime).toDateString() === clickedDate
//     );

//     if (clickedEvent) {
//       setSelectedEvent(clickedEvent); // Set selected event
//     } else {
//       setSelectedEvent(null); // Clear selected event if no event found
//     }
//   };

//   const handleClosePopup = () => {
//     setSelectedEvent(null); // Close the popup by clearing the selected event
//   };

//   return (
//     <>
//       <div className="calendar-head">
//         <h2>Calendar</h2>
//       </div>
//       <hr />
//       <br />
//       <br />
//       <div className="calendarContainer">
//         <div className="calendarTopBar"></div>
//         <div className="calendarContainerinr">
//           <div className="cm-side">
//             <div className="cmSide-heading">
//               <h3>30 Minute meeting</h3>
//             </div>
//           </div>
//           <div className="cc-side">
//             <div className="calendarPg">
//               <p className="text-right">
//                 <span className="bold">Selected Date:</span>{" "}
//                 {date.toDateString()}
//               </p>
//               <div className="calendar-container">
//                 <Calendar
//                   onChange={setDate}
//                   value={date}
//                   tileClassName={eventClassName}
//                   onClickDay={handleClickDay}
//                 />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       {/* Popup to display event details */}
//       {selectedEvent && (
//         <div className="ed-popup">
//           <div className="ed-popup-content">
//             <div className="eventDetail-close-btn" onClick={handleClosePopup}>
//               &times;
//             </div>
//             <h3>Event Details</h3>
//             <div className="event-item">
//               <h6>Start Time:</h6>
//               <p>{selectedEvent.start.dateTime}</p>
//             </div>
//             <div className="event-item">
//               <h6>End Time:</h6>
//               <p>{selectedEvent.end.dateTime}</p>
//             </div>
//             <div className="event-item">
//               <h6>Summary</h6>
//               <p>{selectedEvent.summary}</p>
//             </div>
//             <div className="event-item">
//               <h6>Description</h6>
//               <p>{selectedEvent.description}</p>
//             </div>
//             {selectedEvent.conferenceData && (
//               <div>
//                 <div className="event-item">
//                   <h6>Conference ID:</h6>
//                   <p>{selectedEvent.conferenceData.conferenceId}</p>
//                 </div>
//                 <div className="event-item">
//                   <h6>Conference Solution:</h6>
//                   <p>{selectedEvent.conferenceData.conferenceSolution.name}</p>
//                 </div>
//               </div>
//             )}
//           </div>
//         </div>
//       )}
//     </>
//   );
// }



  // import React, { useState, useEffect, useCallback } from "react";
  // import "./calendar.css";
  // import Calendar from "react-calendar";
  // import { useNavigate } from "react-router-dom";

  // export default function CalendarComponent() {
  //   const [date, setDate] = useState(new Date());
  //   const navigate = useNavigate();
  //   const [events, setEvents] = useState([]);
  //   const [selectedEvents, setSelectedEvents] = useState([]);
  //   const [currentPage, setCurrentPage] = useState(1);
  //   const itemsPerPage = 1;

  //   const fetchEvents = useCallback(async () => {
  //     try {
  //       const token = localStorage.getItem("accessToken");

  //       if (!token) {
  //         console.error("Access token not found");
  //         return;
  //       }

  //       const response = await fetch(
  //         "https://dev-api.ivoz.ai/google-calender/view-events?start_time=2024-05-19&end_time=2024-05-21",
  //         {
  //           headers: {
  //             accept: "application/json",
  //             token: token,
  //           },
  //         }
  //       );
  //       if (response.status === 401) {
  //         navigate("/");
  //         return;
  //       }

  //       if (!response.ok) {
  //         throw new Error("Failed to fetch events");
  //       }

  //       const data = await response.json();
  //       console.log("API Response:", data);

  //       if (!Array.isArray(data)) {
  //         throw new Error("Fetched data is not an array");
  //       }

  //       setEvents(data);
  //     } catch (error) {
  //       console.error("Error fetching events:", error);
  //     }
  //   }, [navigate]);

  //   useEffect(() => {
  //     fetchEvents();
  //   }, [fetchEvents]);

  //   const eventDates = events.map((event) =>
  //     new Date(event.start.dateTime).toDateString()
  //   );

  //   const eventClassName = ({ date }) => {
  //     const dateString = date.toDateString();
  //     return eventDates.includes(dateString) ? "event-date" : null;
  //   };

  //   const handleClickDay = (value) => {
  //     const clickedDate = value.toDateString();
  //     const filteredEvents = events.filter(
  //       (event) => new Date(event.start.dateTime).toDateString() === clickedDate
  //     );

  //     setSelectedEvents(filteredEvents);
  //     setCurrentPage(1); // Reset to the first page
  //   };

  //   const handleClosePopup = () => {
  //     setSelectedEvents([]);
  //     setCurrentPage(1); // Reset to the first page
  //   };

  //   // Pagination logic
  //   const indexOfLastItem = currentPage * itemsPerPage;
  //   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  //   const currentEvents = selectedEvents.slice(indexOfFirstItem, indexOfLastItem);
  //   const totalPages = Math.ceil(selectedEvents.length / itemsPerPage);

  //   const handlePreviousPage = () => {
  //     setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  //   };

  //   const handleNextPage = () => {
  //     setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
  //   };

  //   return (
  //     <>
  //       <div className="calendar-head">
  //         <h2>Calendar</h2>
  //       </div>
  //       <hr />
  //       <br />
  //       <br />
  //       <div className="calendarContainer">
  //         <div className="calendarTopBar"></div>
  //         <div className="calendarContainerinr">
  //           <div className="cm-side">
  //             <div className="cmSide-heading">
  //               <h3>30 Minute meeting</h3>
  //             </div>
  //           </div>
  //           <div className="cc-side">
  //             <div className="calendarPg">
  //               <p className="text-right">
  //                 <span className="bold">Selected Date:</span>{" "}
  //                 {date.toDateString()}
  //               </p>
  //               <div className="calendar-container">
  //                 <Calendar
  //                   onChange={setDate}
  //                   value={date}
  //                   tileClassName={eventClassName}
  //                   onClickDay={handleClickDay}
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //       {/* Popup to display event details */}
  //       {selectedEvents.length > 0 && (
  //         <div className="ed-popup">
  //           <div className="ed-popup-content">
  //             <div className="eventDetail-close-btn" onClick={handleClosePopup}>
  //               &times;
  //             </div>
  //             <h3>Event Details</h3>
  //             {currentEvents.map((event, index) => (
  //               <div key={index} className="event-item">
  //                 <div>
  //                   <h6>Start Time:</h6>
  //                   <p>{event.start.dateTime}</p>
  //                 </div>
  //                 <div>
  //                   <h6>End Time:</h6>
  //                   <p>{event.end.dateTime}</p>
  //                 </div>
  //                 <div>
  //                   <h6>Summary</h6>
  //                   <p>{event.summary}</p>
  //                 </div>
  //                 <div>
  //                   <h6>Description</h6>
  //                   <p>{event.description}</p>
  //                 </div>
  //                 {event.conferenceData && (
  //                   <div>
  //                     <div>
  //                       <h6>Conference ID:</h6>
  //                       <p>{event.conferenceData.conferenceId}</p>
  //                     </div>
  //                     <div>
  //                       <h6>Conference Solution:</h6>
  //                       <p>{event.conferenceData.conferenceSolution.name}</p>
  //                     </div>
  //                   </div>
  //                 )}
  //               </div>
  //             ))}
  //             <div className="pagination">
  //               <button className="primary-btn secondary" onClick={handlePreviousPage} disabled={currentPage === 1}>
  //                 Previous
  //               </button>
  //               <span className="paginationSpn">
  //                 Page {currentPage} of {totalPages}
  //               </span>
  //               <button
  //               className="primary-btn secondary"
  //                 onClick={handleNextPage}
  //                 disabled={currentPage === totalPages}
  //               >
  //                 Next
  //               </button>
  //             </div>
  //           </div>
  //         </div>
  //       )}
  //     </>
  //   );
  // }



import React, { useState, useEffect, useCallback } from "react";
import "./calendar.css";
import Calendar from "react-calendar";
import { useNavigate } from "react-router-dom";

export default function CalendarComponent() {
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const eventsPerPage = 1; // Display only one event per page

  const fetchEvents = useCallback(async () => {
    try {
      const token = localStorage.getItem("accessToken");

      // Check if token exists
      if (!token) {
        console.error("Access token not found");
        return;
      }


      const startDate = new Date(); 
      const endDate = new Date();
      endDate.setDate(startDate.getDate() + 7); // One week later

      // const start_time = startDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
      // const end_time = endDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD

      const response = await fetch(
        // `https://dev-api.ivoz.ai/google-calender/view-events?start_time=${start_time}&end_time=${end_time}`,
        `https://dev-api.ivoz.ai/google-calender/view-events?start_time=2024-05-22&end_time=2024-05-24`,
        {
          headers: {
            accept: "application/json",
            token: token, // Pass token in the headers
          },
        }
      );
      if (response.status === 401) {
        navigate("/");
        return;
      }

      if (!response.ok) {
        throw new Error("Failed to fetch events");
      }

      const data = await response.json();
      console.log("API Response:", data);

      if (!Array.isArray(data)) {
        throw new Error("Fetched data is not an array");
      }

      setEvents(data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  }, [navigate]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const eventDates = events.map((event) =>
    new Date(event.start.dateTime).toDateString()
  );

  const eventClassName = ({ date }) => {
    const dateString = date.toDateString();
    return eventDates.includes(dateString) ? "event-date" : null;
  };

  const handleClickDay = (value) => {
    const clickedDate = value.toDateString();
    const clickedEvents = events.filter(
      (event) => new Date(event.start.dateTime).toDateString() === clickedDate
    );

    setSelectedEvents(clickedEvents); // Set selected events
    setCurrentPage(1); // Reset to the first page
  };

  const handleClosePopup = () => {
    setSelectedEvents([]); // Close the popup by clearing the selected events
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const currentEvents = selectedEvents.slice(
    (currentPage - 1) * eventsPerPage,
    currentPage * eventsPerPage
  );

  const totalPages = Math.ceil(selectedEvents.length / eventsPerPage);

  return (
    <>
      <div className="calendar-head">
        <h2>Calendar</h2>
      </div>
      <hr />
      <br />
      <br />
      <div className="calendarContainer">
        <div className="calendarTopBar"></div>
        <div className="calendarContainerinr">
          <div className="cm-side">
            <div className="cmSide-heading">
              <h3>30 Minute meeting</h3>
            </div>
          </div>
          <div className="cc-side">
            <div className="calendarPg">
              <p className="text-right">
                <span className="bold">Selected Date:</span>{" "}
                {date.toDateString()}
              </p>
              <div className="calendar-container">
                <Calendar
                  onChange={setDate}
                  value={date}
                  tileClassName={eventClassName}
                  onClickDay={handleClickDay}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Popup to display event details */}
      {selectedEvents.length > 0 && (
        <div className="ed-popup">
          <div className="ed-popup-content">
            <div className="eventDetail-close-btn" onClick={handleClosePopup}>
              &times;
            </div>
            <h3>Event Details</h3>
            {currentEvents.map((event, index) => (
              <div key={index} className="event-item">
                <div>
                  <h6>Start Time:</h6>
                  <p>{event.start.dateTime}</p>
                </div>
                <div>
                  <h6>End Time:</h6>
                  <p>{event.end.dateTime}</p>
                </div>
                <div>
                  <h6>Summary</h6>
                  <p>{event.summary}</p>
                </div>
                <div>
                  <h6>Description</h6>
                  <p>{event.description}</p>
                </div>
                {event.conferenceData && (
                  <div>
                    <div>
                      <h6>Conference ID:</h6>
                      <p>{event.conferenceData.conferenceId}</p>
                    </div>
                    <div>
                      <h6>Conference Solution:</h6>
                      <p>{event.conferenceData.conferenceSolution.name}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
            <div className="pagination">
              <button
                className="primary-btn secondary"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="paginationSpn">Page {currentPage} of {totalPages}</span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
