import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './userform.css';

export default function Userform() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: ''
  });

  const getLinkIdFromURL = () => {
    const hashParams = new URLSearchParams(window.location.hash.split("?")[1]);
    const linkId = hashParams.get('link_id');
    return linkId;
  };

  useEffect(() => {
    const fetchPhoneNumber = async () => {
      const linkId = getLinkIdFromURL();
      if (linkId) {
        try {
          const response = await axios.get(`https://dev-api.ivoz.ai/cid-invite-link?link_id=${linkId}`, {
            headers: {
              'accept': 'application/json'
            }
          });
          if (response.data.link_validity) {
            setFormData(prevState => ({
              ...prevState,
              phoneNumber: response.data.phone
            }));
          }
        } catch (error) {
          console.error('Error fetching the phone number:', error);
        }
      }
    };

    fetchPhoneNumber();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://dev-api.ivoz.ai/cid-invite-link', {
        link_id: getLinkIdFromURL(),
        email: formData.email,
        name: formData.fullName
      }, {
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      if (response.data.status) {
        console.log('Invitation sent successfully!');
        alert("profile updated successfully");
      }
    } catch (error) {
      console.error('Error sending invitation:', error);
    }
  };

  return (
    <div className="userForm">
      <div className="container">
        <div className="userDetailForm">
          <form action="" className='userDetails' onSubmit={handleSubmit}>
            <label htmlFor="fullName">Full Name:</label><br />
            <input
              className='userDetailInr'
              type="text"
              name="fullName"
              placeholder='Full name'
              value={formData.fullName}
              onChange={handleChange}
            /><br />
            <label htmlFor="email">Email:</label><br />
            <input
              className='userDetailInr'
              type="email"
              name="email"
              placeholder='Email'
              value={formData.email}
              onChange={handleChange}
            /><br />
            <label htmlFor="phoneNumber">Phone Number:</label><br />
            <input
              className='userDetailInr'
              type="text"
              name="phoneNumber"
              placeholder='Mobile No.'
              value={formData.phoneNumber}
              readOnly
            /><br />
            <button type="submit">Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
}
