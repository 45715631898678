// import React, { useEffect, useRef, useState } from "react";
// import Chart from "chart.js/auto";
// import "./Dashboard.css";
// import { useTable } from "react-table";
// import { useNavigate } from "react-router-dom";

// export default function CalenderMeeting() {
//   const [customerMeetings, setCustomerMeetings] = useState([]);
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [searchQuery, setSearchQuery] = useState("");
//   const navigate = useNavigate();
//   const chartRef = useRef(null);
//   const chartInstance = useRef(null);
//   const barChartRef = useRef(null);
//   const barChartInstance = useRef(null);


//   useEffect(() => {
//     const accessToken = localStorage.getItem("accessToken");
//     if (!accessToken) {
//       console.error("Token not found in local storage");
//       navigate("/");
//       return;
//     }

//     fetch("https://dev-api.ivoz.ai/user/callbacks?page=1&page_size=20", {
//       headers: {
//         accept: "application/json",
//         token: accessToken,
//       },
//     })
    
//       .then((response) => {
//         if (response.status === 401) {
//           throw new Error("Invalid or expired token");
//         }
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((data) => {
//         if (Array.isArray(data)) {
//           const extractedMeetings = data.map((item, index) => {
//             const utcTime = new Date(item.time);
//             const localTime = utcTime.toLocaleString();
//             return {
//               id: index + 1,
//               time: localTime.split(" ")[1],
//               customerName: item.data.cust_name,
//               phoneNumber: item.data.phone,
//               date: localTime.split(",")[0],
//             };
//           });
//           setCustomerMeetings(extractedMeetings);
//         } else {
//           throw new Error("Data is not in expected format");
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching customer meetings:", error);
//       });
//   }, [navigate]);

//   // Function to handle date selection
//   const handleDateChange = (event) => {
//     const selectedDate = event.target.value;
//     setSelectedDate(selectedDate);
//   };

//   // Filter customer meetings based on selected date
//   const filteredMeetings = selectedDate
//     ? customerMeetings.filter((meeting) => meeting.date === selectedDate)
//     : customerMeetings;

//   // Filter customer meetings based on search query
//   const filteredMeetingsWithSearch = searchQuery
//   ? filteredMeetings.filter(
//       (meeting) =>
//         meeting.customerName.toLowerCase().includes(searchQuery) ||
//         meeting.phoneNumber.includes(searchQuery)
//     )
//   : filteredMeetings;

//   // Table columns
//   const columns = React.useMemo(
//     () => [
//       { Header: "S.No", accessor: "id" },
//       { Header: "Time", accessor: "time" },
//       { Header: "Customer Name", accessor: "customerName" },
//       { Header: "Phone number", accessor: "phoneNumber" },
//       { Header: "Date", accessor: "date" },
//     ],
//     []
//   );

//   // React-table hook
//   const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
//     useTable({
//       columns,
//       data: filteredMeetingsWithSearch,
//     });

//   // Function to handle search input change
//   const handleSearchInputChange = (event) => {
//     const query = event.target.value.toLowerCase();
//     setSearchQuery(query);
//   };

//   useEffect(() => {
//     if (chartRef && chartRef.current) {
//       if (chartInstance.current) {
//         chartInstance.current.destroy();
//       }
//       const ctx = chartRef.current.getContext("2d");

//       const filledPercentage = 60;
//       const remainingPercentage = 100 - filledPercentage;

//       const filledGradient = ctx.createLinearGradient(0, 0, 0, 150);
//       filledGradient.addColorStop(0, "#ff7f0e");
//       filledGradient.addColorStop(1, "#ffecb3");

//       const unfilledColor = "#cccccc";

//       chartInstance.current = new Chart(ctx, {
//         type: "doughnut",
//         data: {
//           labels: ["Meeting Reports"],
//           datasets: [
//             {
//               data: [filledPercentage, remainingPercentage],
//               backgroundColor: [filledGradient, unfilledColor],
//             },
//           ],
//         },
//         options: {
//           cutout: "70%",
//           plugins: {
//             legend: {
//               display: false,
//             },
//             tooltip: {
//               enabled: false,
//             },
//           },
//           elements: {
//             center: {
//               text: filledPercentage + "%",
//               color: "#000000",
//               fontStyle: "Arial",
//               sidePadding: 20,
//               minFontSize: 20,
//               maxFontSize: 40,
//               lineHeight: 25,
//             },
//           },
//         },
//       });
//     }

//     if (barChartRef && barChartRef.current) {
//       if (barChartInstance.current) {
//         barChartInstance.current.destroy();
//       }
//       const ctx = barChartRef.current.getContext("2d");

//       const data = {
//         labels: ["In progress", "Done", "Pending"],
//         datasets: [
//           {
//             label: "Meeting Reports",
//             data: [242, 172, 785],
//             backgroundColor: [
//               "#ff7f0e", // Orange for "In progress"
//               "#007bff", // Blue for "Done"
//               "#6a0dad", // Purple for "Pending"
//             ],
//             borderWidth: 0,
//             barThickness: 11,
//           },
//         ],
//       };

//       barChartInstance.current = new Chart(ctx, {
//         type: "bar",
//         data: data,
//         options: {
//           indexAxis: "y",
//           scales: {
//             x: {
//               display: false,
//             },
//             y: {
//               display: false,
//             },
//           },
//           plugins: {
//             legend: {
//               display: false,
//             },
//           },
//         },
//       });
//     }

//     return () => {
//       if (chartInstance.current) {
//         chartInstance.current.destroy();
//       }
//       if (barChartInstance.current) {
//         barChartInstance.current.destroy();
//       }
//     };
//   }, []);

//   return (
//     <>
//       <div className="wrapper-main">
//         <div className="upperBoard">
//           <div className="upperboardInner">
//             <div className="uprBoardLeft">
//               <div className="totalNumber">
//                 <div className="tn-inner">
//                   <div className="th-head">
//                     <h6>Total Calls</h6>
//                   </div>
//                   <div className="tn-number">
//                     <p>45</p>
//                   </div>
//                 </div>
//                 <div className="tn-inner">
//                   <div className="th-head">
//                     <h6>Call Back Request</h6>
//                   </div>
//                   <div className="tn-number">
//                     <p>{customerMeetings.length}</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="uprBoardRyt">
//               <div className="meetingReports">
//                 <div className="meetingReport-inner">
//                   <div className="mr-heading">
//                     <h4>Meeting Reports</h4>
//                   </div>
//                   <div className="reportsSec">
//                     <div className="mr-reports">
//                       <div className="mrReport-inr">
//                         <div className="mrReportdiv">
//                           <div className="mr-head">Total Meetings</div>
//                           <div className="mr-head-data">242</div>
//                         </div>
//                         <div className="mrReportdiv">
//                           <div className="mr-head">In progress</div>
//                           <div className="mr-head-data">172</div>
//                         </div>
//                         <div className="mrReportdiv">
//                           <div className="mr-head">Done</div>
//                           <div className="mr-head-data">785</div>
//                         </div>
//                       </div>
//                     </div>
//                     <div className="barChart">
//                       <canvas ref={barChartRef}></canvas>
//                     </div>
//                     <div className="circleChart">
//                       <canvas ref={chartRef}></canvas>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="appointments-report">
//                 <div className="ar-heading">
//                   <h4>All Appointments</h4>
//                   <p>11 Feb,2024, Friday</p>
//                 </div>
//                 <div className="sorting-detail">
//                   <div className="sorting-details">
//                     <div className="sorting-btn">
//                       <select className="customDate" onChange={handleDateChange}>
//                         <option value="">Select Date</option>
//                         {customerMeetings.map((meeting, index) => (
//                           <option key={index} value={meeting.date}>
//                             {meeting.date}
//                           </option>
//                         ))}
//                       </select>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="downBoard">
//           <div className="meeting-table">
//             <div className="table-container">
//              <div className="head-with-searchbar">
//              <h3>All Customer Meetings</h3>
//               <input
//               type="text"
//               placeholder="Search..."
//               value={searchQuery}
//               onChange={handleSearchInputChange}
//             />
//              </div>
//               <table {...getTableProps()}>
//                 <thead>
//                   {headerGroups.map((headerGroup) => (
//                     <tr {...headerGroup.getHeaderGroupProps()}>
//                       {headerGroup.headers.map((column) => (
//                         <th {...column.getHeaderProps()}>
//                           {column.render("Header")}
//                         </th>
//                       ))}
//                     </tr>
//                   ))}
//                 </thead>
//                 <tbody {...getTableBodyProps()}>
//                   {rows.map((row, index) => {
//                     prepareRow(row);
//                     return (
//                       <tr
//                         {...row.getRowProps()}
//                         className={index % 2 === 1 ? "grey-row" : ""}
//                       >
//                         {row.cells.map((cell) => {
//                           return (
//                             <td {...cell.getCellProps()}>
//                               {cell.render("Cell")}
//                             </td>
//                           );
//                         })}
//                       </tr>
//                     );
//                   })}
//                 </tbody>
//               </table>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// }

import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import "./Dashboard.css";
import { useTable } from "react-table";
import { useNavigate } from "react-router-dom";

export default function CalenderMeeting() {
  const [customerMeetings, setCustomerMeetings] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const barChartRef = useRef(null);
  const barChartInstance = useRef(null);
  const [callCount, setCallCount] = useState(0);


  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("Token not found in local storage");
      navigate("/");
      return;
    }


    fetch("https://dev-api.ivoz.ai/user/overview-analytics?start_date=2024-05-13&end_date=2024-05-20", {
      headers: {
        accept: "application/json",
        token: accessToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Extract call count from the response
        const callCount = data.call_count;
        setCallCount(callCount); // Update the state variable
      })
      .catch((error) => {
        console.error("Error fetching call count:", error);
      });

    fetch("https://dev-api.ivoz.ai/user/callbacks?page=1&page_size=20", {
      headers: {
        accept: "application/json",
        token: accessToken,
      },
    })
    
      .then((response) => {
        if (response.status === 401) {
          throw new Error("Invalid or expired token");
        }
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          const extractedMeetings = data.map((item, index) => {
            const utcTime = new Date(item.time);
            const localTime = utcTime.toLocaleString();
            return {
              id: index + 1,
              time: localTime.split(" ")[1],
              customerName: item.data.cust_name,
              phoneNumber: item.data.phone,
              date: localTime.split(",")[0],
            };
          });
          setCustomerMeetings(extractedMeetings);
        } else {
          throw new Error("Data is not in expected format");
        }
      })
      .catch((error) => {
        console.error("Error fetching customer meetings:", error);
      });
  }, [navigate]);

  // Function to handle date selection
  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setSelectedDate(selectedDate);
  };

  // Filter customer meetings based on selected date
  const filteredMeetings = selectedDate
    ? customerMeetings.filter((meeting) => meeting.date === selectedDate)
    : customerMeetings;

  // Filter customer meetings based on search query
  const filteredMeetingsWithSearch = searchQuery
  ? filteredMeetings.filter(
      (meeting) =>
        meeting.customerName.toLowerCase().includes(searchQuery) ||
        meeting.phoneNumber.includes(searchQuery)
    )
  : filteredMeetings;

  // Table columns
  const columns = React.useMemo(
    () => [
      { Header: "S.No", accessor: "id" },
      { Header: "Time", accessor: "time" },
      { Header: "Date", accessor: "date" },
      { Header: "Customer Name", accessor: "customerName" },
      { Header: "Phone number", accessor: "phoneNumber" },
      
    ],
    []
  );

  // React-table hook
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: filteredMeetingsWithSearch,
    });

  // Function to handle search input change
  const handleSearchInputChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      const ctx = chartRef.current.getContext("2d");

      const filledPercentage = 60;
      const remainingPercentage = 100 - filledPercentage;

      const filledGradient = ctx.createLinearGradient(0, 0, 0, 150);
      filledGradient.addColorStop(0, "#ff7f0e");
      filledGradient.addColorStop(1, "#ffecb3");

      const unfilledColor = "#cccccc";

      chartInstance.current = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: ["Meeting Reports"],
          datasets: [
            {
              data: [filledPercentage, remainingPercentage],
              backgroundColor: [filledGradient, unfilledColor],
            },
          ],
        },
        options: {
          cutout: "70%",
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          elements: {
            center: {
              text: filledPercentage + "%",
              color: "#000000",
              fontStyle: "Arial",
              sidePadding: 20,
              minFontSize: 20,
              maxFontSize: 40,
              lineHeight: 25,
            },
          },
        },
      });
    }

    if (barChartRef && barChartRef.current) {
      if (barChartInstance.current) {
        barChartInstance.current.destroy();
      }
      const ctx = barChartRef.current.getContext("2d");

      const data = {
        labels: ["In progress", "Done", "Pending"],
        datasets: [
          {
            label: "Meeting Reports",
            data: [242, 172, 785],
            backgroundColor: [
              "#ff7f0e", // Orange for "In progress"
              "#007bff", // Blue for "Done"
              "#6a0dad", // Purple for "Pending"
            ],
            borderWidth: 0,
            barThickness: 11,
          },
        ],
      };

      barChartInstance.current = new Chart(ctx, {
        type: "bar",
        data: data,
        options: {
          indexAxis: "y",
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      if (barChartInstance.current) {
        barChartInstance.current.destroy();
      }
    };
  }, []);

  return (
    <>
      <div className="wrapper-main">
        <div className="upperBoard">
          <div className="upperboardInner">
            <div className="uprBoardLeft">
              <div className="totalNumber">
                <div className="tn-inner">
                  <div className="th-head">
                    <h6>Total Calls</h6>
                  </div>
                  <div className="tn-number">
                  <p>{callCount}</p> 
                  </div>
                </div>
                <div className="tn-inner">
                  <div className="th-head">
                    <h6>Call Back Request</h6>
                  </div>
                  <div className="tn-number">
                    <p>{customerMeetings.length}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="uprBoardRyt">
              <div className="meetingReports">
                <div className="meetingReport-inner">
                  <div className="mr-heading">
                    <h4>Meeting Reports</h4>
                  </div>
                  <div className="reportsSec">
                    <div className="mr-reports">
                      <div className="mrReport-inr">
                        <div className="mrReportdiv">
                          <div className="mr-head">Total Meetings</div>
                          <div className="mr-head-data">242</div>
                        </div>
                        <div className="mrReportdiv">
                          <div className="mr-head">In progress</div>
                          <div className="mr-head-data">172</div>
                        </div>
                        <div className="mrReportdiv">
                          <div className="mr-head">Done</div>
                          <div className="mr-head-data">785</div>
                        </div>
                      </div>
                    </div>
                    <div className="barChart">
                      <canvas ref={barChartRef}></canvas>
                    </div>
                    <div className="circleChart">
                      <canvas ref={chartRef}></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div className="appointments-report">
                <div className="ar-heading">
                  <h4>All Appointments</h4>
                  <p>11 Feb,2024, Friday</p>
                </div>
                <div className="sorting-detail">
                  <div className="sorting-details">
                    <div className="sorting-btn">
                      <select className="customDate" onChange={handleDateChange}>
                        <option value="">Select Date</option>
                        {customerMeetings.map((meeting, index) => (
                          <option key={index} value={meeting.date}>
                            {meeting.date}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="downBoard">
          <div className="meeting-table">
            <div className="table-container">
             <div className="head-with-searchbar">
             <h3>All Customer Meetings</h3>
              <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
             </div>
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className={index % 2 === 1 ? "grey-row" : ""}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className={cell.value.length > 10 ? "long-text" : ""}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
