import React, { useState, useEffect } from "react";
import hubspot from "../Assets/hubspotIcon.png";
import Zoho from "../Assets/zohoIcon.png";
import Calender from "../Assets/calenderIcon.png";

const DropdownButton = ({
  buttonText,
  isConnected,
  onDisable,
  onConnect,
  connectRedirectURL,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <div className={`dropdown ${isConnected ? "" : "notconnected"}`}>
      <button
        className={`dropbtn ${isConnected ? "" : "notconnected"}`}
        onClick={isConnected ? toggleDropdown : onConnect}
      >
        {buttonText} {isConnected && (showDropdown ? "▲" : "▼")}
      </button>
      {showDropdown && (
        <div className="dropdown-content">
          {isConnected && <button onClick={onDisable}>Disable</button>}
          {!isConnected && connectRedirectURL && (
            <a href={connectRedirectURL}>Connect</a>
          )}
        </div>
      )}
    </div>
  );
};

export default function CRM() {
  const [integrationStatus, setIntegrationStatus] = useState({
    google_calendar: false,
    hubspot_crm: false,
    zoho_crm: false,
  });
  const [loading, setLoading] = useState(true);
  const [googleCalendarRedirectURL, setGoogleCalendarRedirectURL] =
    useState("");
  const [zohoCRMRedirectURL, setZohoCRMRedirectURL] = useState("");

  // Function to handle connecting Google Calendar
  const handleConnectGoogleCalendar = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      console.error("Access token not found in local storage");
      return;
    }

    fetch("https://dev-api.ivoz.ai/google-calender/connect", {
      method: "GET",
      headers: {
        accept: "application/json",
        token: accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.redirect_url) {
          // Save the redirect URL
          setGoogleCalendarRedirectURL(data.redirect_url);
          // Redirect the user to the Google Calendar authentication page
          window.location.href = data.redirect_url;
        } else {
          console.error("Redirect URL not found in response");
        }
      })
      .catch((error) => {
        console.error("Error connecting Google Calendar:", error);
      });
  };

  // Function to handle disabling Google Calendar
  const handleDisableGoogleCalendar = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      console.error("Access token not found in local storage");
      return;
    }

    fetch("https://dev-api.ivoz.ai/google-calender/", {
      method: "DELETE",
      headers: {
        accept: "application/json",
        token: accessToken,
      },
    })
      .then((response) => {
        if (response.ok) {
          // Integration deleted successfully, update integration status
          setIntegrationStatus((prevStatus) => ({
            ...prevStatus,
            google_calendar: false,
          }));
          console.log("Google Calendar integration deleted successfully");
          alert("Google Calendar disabled successfully");
        } else {
          console.error("Failed to delete Google Calendar integration");
        }
      })
      .catch((error) => {
        console.error("Error deleting Google Calendar integration:", error);
      });
  };

  // Function to handle connecting Zoho CRM
  const handleConnectZohoCRM = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      console.error("Access token not found in local storage");
      return;
    }

    fetch("https://dev-api.ivoz.ai/zoho/connect", {
      method: "GET",
      headers: {
        accept: "application/json",
        token: accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.redirect_url) {
          // Save the redirect URL
          setZohoCRMRedirectURL(data.redirect_url);
          // Redirect the user to the Zoho CRM authentication page
          window.location.href = data.redirect_url;
        } else {
          console.error("Redirect URL not found in response");
        }
      })
      .catch((error) => {
        console.error("Error connecting Zoho CRM:", error);
      });
  };

  // Function to handle disabling Zoho CRM
  const handleDisableZohoCRM = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      console.error("Access token not found in local storage");
      return;
    }

    fetch("https://dev-api.ivoz.ai/zoho", {
      method: "DELETE",
      headers: {
        accept: "application/json",
        token: accessToken,
      },
    })
      .then((response) => {
        if (response.ok) {
          // Integration deleted successfully, update integration status
          setIntegrationStatus((prevStatus) => ({
            ...prevStatus,
            zoho_crm: false,
          }));
          console.log("Zoho CRM integration deleted successfully");
          alert("Zoho CRM disabled successfully");
        } else {
          console.error("Failed to delete Zoho CRM integration");
        }
      })
      .catch((error) => {
        console.error("Error deleting Zoho CRM integration:", error);
      });
  };
  // Function to handle connecting to HubSpot
  const handleConnectHubSpot = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      console.error("Access token not found in local storage");
      return;
    }

    fetch("https://dev-api.ivoz.ai/hubspot/connect", {
      method: "GET",
      headers: {
        accept: "application/json",
        token: accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.redirect_url) {
          // Redirect the user to the HubSpot authentication page
          window.location.href = data.redirect_url;
        } else {
          console.error("Redirect URL not found in response");
        }
      })
      .catch((error) => {
        console.error("Error connecting to HubSpot:", error);
      });
  };
  // Function to handle disabling HubSpot integration
  const handleDisableHubSpot = () => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      console.error("Access token not found in local storage");
      return;
    }

    fetch("https://dev-api.ivoz.ai/hubspot/", {
      method: "DELETE",
      headers: {
        accept: "application/json",
        token: accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          // Integration deleted successfully, update integration status
          setIntegrationStatus((prevStatus) => ({
            ...prevStatus,
            hubspot_crm: false,
          }));
          console.log("HubSpot integration deleted successfully");
          alert("HubSpot integration disabled successfully");
        } else {
          console.error("Failed to delete HubSpot integration");
        }
      })
      .catch((error) => {
        console.error("Error deleting HubSpot integration:", error);
      });
  };

  // useEffect to fetch integration status
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (!accessToken) {
      console.error("Access token not found in local storage");
      setLoading(false);
      return;
    }

    fetch("https://dev-api.ivoz.ai/integration/", {
      method: "GET",
      headers: {
        accept: "application/json",
        token: accessToken,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIntegrationStatus(data);
        setLoading(false); // Set loading to false when data is fetched
        console.log("Integration Status:", data); // Log the integration status response
      })
      .catch((error) => {
        console.error("Error fetching integration status:", error);
        setLoading(false); // Set loading to false even if there's an error
      });
  }, []);

  return (
    <>
      <div className="crm-integration">
        <h2>Integrations</h2>
        <p className="crm-para">
          Seamlessly connect and synchronize your CRM system with other
          essential tools and platforms.
        </p>

        <div className="integrations-tab">
          {!loading && (
            <>
              {/* CALENDAR INTEGRATION */}
              <div
                className={`innerMulti-tabs calendarDiv ${
                  integrationStatus.google_calendar
                    ? ""
                    : "calendar-not-connected"
                }`}
              >
                <div className="tab-cards">
                  <div className="tab-cards-head">
                    <div className="tab-cardIcon">
                      <img src={Calender} alt="calendarLogo" />
                    </div>
                    <div className="tab-cardHeading">
                      <h3>Google Calendar</h3>
                    </div>
                  </div>
                  <div className="tab-cardPara">
                    <p>
                      Sync CRM activities with Google Calendar for seamless
                      scheduling
                    </p>
                  </div>
                </div>
                <div className="tab-cardButtons">
                  <DropdownButton
                    buttonText={
                      integrationStatus.google_calendar
                        ? "Connected"
                        : "Connect"
                    }
                    isConnected={integrationStatus.google_calendar}
                    onDisable={handleDisableGoogleCalendar}
                    onConnect={handleConnectGoogleCalendar}
                    connectRedirectURL={googleCalendarRedirectURL}
                  />
                </div>
              </div>
              {/* HUBSPOT INTEGRATION */}
              <div
                className={`innerMulti-tabs hubspotDiv ${
                  integrationStatus.hubspot_crm ? "" : "hubspot-not-connected"
                }`}
              >
                <div className="tab-cards">
                  <div className="tab-cards-head">
                    <div className="tab-cardIcon">
                      <img src={hubspot} alt="hubspotLogo" />
                    </div>
                    <div className="tab-cardHeading">
                      <h3>Hubspot</h3>
                    </div>
                  </div>
                  <div className="tab-cardPara">
                    <p>
                      Elevate your CRM capabilities with seamless integration
                      with HubSpot
                    </p>
                  </div>
                </div>
                <div className="tab-cardButtons">
                  <DropdownButton
                    buttonText={
                      integrationStatus.hubspot_crm ? "Connected" : "Connect"
                    }
                    isConnected={integrationStatus.hubspot_crm}
                    onConnect={handleConnectHubSpot}
                    onDisable={handleDisableHubSpot}
                  />
                  
                </div>
              </div>
              {/* Zoho CRM integration */}
              <div
                className={`innerMulti-tabs zohoDiv ${
                  integrationStatus.zoho_crm ? "" : "zoho-not-connected"
                }`}
              >
                <div className="tab-cards">
                  <div className="tab-cards-head">
                    <div className="tab-cardIcon">
                      <img src={Zoho} alt="zohoIcon" />
                    </div>
                    <div className="tab-cardHeading">
                      <h3>Zoho</h3>
                    </div>
                  </div>
                  <div className="tab-cardPara">
                    <p>Optimize your CRM workflow with Zoho CRM seamless integration</p>
                  </div>
                </div>
                <div className="tab-cardButtons">
                  <DropdownButton
                    buttonText={
                      integrationStatus.zoho_crm ? "Connected" : "Connect"
                    }
                    isConnected={integrationStatus.zoho_crm}
                    onConnect={handleConnectZohoCRM}
                    onDisable={handleDisableZohoCRM} 
                    connectRedirectURL={zohoCRMRedirectURL}
                  />
                </div>
              </div>
            </>
          )}
          {loading && <p>Loading...</p>}
        </div>
      </div>
    </>
  );
}
