// import React, { useState, useEffect } from "react";
// import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
// import "../ProfileManagement/userMain.css";
// import "./Dashboard.css";
// import userProfile from "../Assets/userProfile.png";

// export default function AdminDashboard() {
//   const [selectedMenuItem, setSelectedMenuItem] = useState("/admin-Dashboard/crmReports");
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [dashboardMenuOpen, setDashboardMenuOpen] = useState(false);
//   const [integrationsMenuOpen, setIntegrationsMenuOpen] = useState(false);
//   const [calendarMenuOpen, setCalendarMenuOpen] = useState(false);
//   const [googleCalendarExists, setGoogleCalendarExists] = useState(false);
//   const calendarMenuItems = [
//     { label: "Calendar", path: "/calendar-board" },
//   ];
//   useEffect(() => {
//     const savedSelectedMenuItem = localStorage.getItem("selectedMenuItem");
//     if (savedSelectedMenuItem) {
//       setSelectedMenuItem(savedSelectedMenuItem);
//     }
//   }, []);
//   useEffect(() => {
//     if (location.pathname === "/admin-Dashboard") {
//       navigate("/admin-Dashboard/crmReports");
//       setSelectedMenuItem("/admin-Dashboard/crmReports");
//     }
//   }, [location.pathname, navigate]);
//   useEffect(() => {
//     const fetchGoogleCalendar = async () => {
//       try {
//         const accessToken = localStorage.getItem("accessToken");
//         if (!accessToken) {
//           console.error("Access token not found");
//           return;
//         }
//         const response = await fetch("https://dev-api.ivoz.ai/google-calender/", {
//           method: "GET",
//           headers: {
//             accept: "application/json",
//             token: accessToken,
//           },
//         });
//         if (response.ok) {
//           const data = await response.json();
//           setGoogleCalendarExists(data.status);
//         } else {
//           console.error("Failed to fetch Google Calendar data");
//         }
//       } catch (error) {
//         console.error("Error fetching Google Calendar data:", error);
//       }
//     };
//     fetchGoogleCalendar();
//   }, []);
//   const handleMenuItemClick = (path) => {
//     setSelectedMenuItem(path);
//     localStorage.setItem("selectedMenuItem", path);
//     navigate(path);
//   };
//   const toggleMenu = (menu) => {
//     switch (menu) {
//       case "dashboard":
//         setDashboardMenuOpen(!dashboardMenuOpen);
//         setIntegrationsMenuOpen(false);
//         setCalendarMenuOpen(false);
//         break;
//       case "integrations":
//         setIntegrationsMenuOpen(!integrationsMenuOpen);
//         setDashboardMenuOpen(false);
//         setCalendarMenuOpen(false);
//         break;
//       case "calendar":
//         setCalendarMenuOpen(!calendarMenuOpen);
//         setDashboardMenuOpen(false);
//         setIntegrationsMenuOpen(false);
//         break;
//       default:
//         break;
//     }
//   };
//   const handleLinkClick = (path) => {
//     setSelectedMenuItem(path);
//     localStorage.setItem("selectedMenuItem", path);
//     setIsMenuOpen(false); 
//   };
//   const handleCheckboxClick = () => {
//     setIsMenuOpen(false); 
//   };
//   return (
//     <>
//       <div className={`top-bar adminTopBar ${isMenuOpen ? "menu-open" : "menu-closed"}`}>
//         <div className="img_wrapper">
//           <Link to="/profile-dashboard">
//             <div className="img">
//               <img src={userProfile} alt="userProfile" />
//             </div>
//           </Link>
//         </div>
//         <div
//           className={`mobile_sec open_close ${
//             dashboardMenuOpen || integrationsMenuOpen || calendarMenuOpen ? "menu-open" : ""
//           }`}
//           onClick={() => setIsMenuOpen(!isMenuOpen)}
//         >
//           <span className="openSpan"></span>
//           <span className="openSpan"></span>
//           <span className="openSpan"></span>
//         </div>
//       </div>
//       <div className={`main-board ${isMenuOpen ? "menu-open" : ""}`}>
//         <div className={`db-signoutBtn left-board ${isMenuOpen ? "open" : "closed"}`}>
//           <div className="left-board-inner">
//             <div className="left-side-menu-bar">
//               <div className="lb-board-heading">
//                 <h2 className="lb-head">Dashboard</h2>
//                 <div className={`lb-head-icon ${dashboardMenuOpen ? "showDropdown" : ""}`} onClick={() => toggleMenu("dashboard")}>
//                   <i className="fa-solid fa-angle-down"></i>
//                 </div>
//               </div>
//               <div className={`lb-menu-list ${dashboardMenuOpen ? "showDropdown" : ""}`}>
//                 {[
//                   { label: "Reports", path: "/admin-Dashboard/crmReports" },
//                   { label: "Analytics", path: "/admin-Dashboard/calenderMeeting" },
//                   { label: "Questions", path: "/admin-Dashboard/questions" },
//                 ].map((item, index) => (
//                   <label key={index} className="checkbox-container">
//                     <input
//                       type="radio"
//                       id={`menuItem${index}`}
//                       checked={selectedMenuItem === item.path}
//                       onChange={() => handleMenuItemClick(item.path)}
//                       onClick={handleCheckboxClick} 
//                     />
//                     <span className="checkmark"></span>
//                     <Link
//                       className="pm-link"
//                       to={item.path}
//                       onClick={() => handleLinkClick(item.path)}
//                     >
//                       <span className={`pm-span ${selectedMenuItem === item.path ? "active" : ""}`}>
//                         {item.label}
//                       </span>
//                     </Link>
//                   </label>
//                 ))}
//               </div>
//             </div>
//             {googleCalendarExists && (
//               <div className="left-side-menu-bar calendar_bar">
//                 <div className="lb-board-heading">
//                   <h2 className="lb-head ">Calendar</h2>
//                   <div className={`lb-head-icon ${calendarMenuOpen ? "showDropdown" : ""}`} onClick={() => toggleMenu("calendar")}>
//                     <i className="fa-solid fa-angle-down"></i>
//                   </div>
//                 </div>
//                 <div className={`lb-menu-list ${calendarMenuOpen ? "showDropdown" : ""}`}>
//                   {calendarMenuItems.map((item, index) => (
//                     <label key={index} className="checkbox-container">
//                       <input
//                         type="radio"
//                         id={`menuItem${index}`}
//                         checked={selectedMenuItem === item.path}
//                         onChange={() => handleMenuItemClick(item.path)}
//                         onClick={handleCheckboxClick} 
//                       />
//                       <span className="checkmark"></span>
//                       <Link
//                         className="pm-link"
//                         to={item.path}
//                         onClick={() => handleLinkClick(item.path)}
//                       >
//                         <span className={`pm-span ${selectedMenuItem === item.path ? "active" : ""}`}>
//                           {item.label}
//                         </span>
//                       </Link>
//                     </label>
//                   ))}
//                 </div>
//               </div>
//             )}

//             <div className="left-side-menu-bar">
//               <div className="lb-board-heading">
//                 <h2 className="lb-head ">Integrations</h2>
//                 <div className={`lb-head-icon ${integrationsMenuOpen ? "showDropdown" : ""}`} onClick={() => toggleMenu("integrations")}>
//                   <i className="fa-solid fa-angle-down"></i>
//                 </div>
//               </div>
//               <div className={`lb-menu-list ${integrationsMenuOpen ? "showDropdown" : ""}`}>
//                 {[
//                   { label: "CRM", path: "/admin-Dashboard/crm" },
//                 ].map((item, index) => (
//                   <label key={index} className="checkbox-container">
//                     <input
//                       type="radio"
//                       id={`menuItem${index}`}
//                       checked={selectedMenuItem === item.path}
//                       onChange={() => handleMenuItemClick(item.path)}
//                       onClick={handleCheckboxClick} 
//                     />
//                     <span className="checkmark"></span>
//                     <Link
//                       className="pm-link"
//                       to={item.path}
//                       onClick={() => handleLinkClick(item.path)}
//                     >
//                       <span className={`pm-span ${selectedMenuItem === item.path ? "active" : ""}`}>
//                         {item.label}
//                       </span>
//                     </Link>
//                   </label>
//                 ))}
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="right-board">
//           <Outlet />
//         </div>
//       </div>
//     </>
//   );
// }

import React, { useState, useEffect } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import "../ProfileManagement/userMain.css";
import "./Dashboard.css";
import userProfile from "../Assets/userProfile.png";

export default function AdminDashboard() {
  const [selectedMenuItem, setSelectedMenuItem] = useState("/admin-Dashboard/crmReports");
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [dashboardMenuOpen, setDashboardMenuOpen] = useState(false);
  const [integrationsMenuOpen, setIntegrationsMenuOpen] = useState(false);
  const [googleCalendarExists, setGoogleCalendarExists] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const calendarMenuItems = [
    { label: "Calendar", path: "/calendar-board" },
  ];

  useEffect(() => {
    const savedSelectedMenuItem = localStorage.getItem("selectedMenuItem");
    if (savedSelectedMenuItem) {
      setSelectedMenuItem(savedSelectedMenuItem);
    }
  }, []);

  useEffect(() => {
    if (location.pathname === "/admin-Dashboard") {
      navigate("/admin-Dashboard/crmReports");
      setSelectedMenuItem("/admin-Dashboard/crmReports");
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    const fetchGoogleCalendar = async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        if (!accessToken) {
          console.error("Access token not found");
          return;
        }
        const response = await fetch("https://dev-api.ivoz.ai/google-calender/", {
          method: "GET",
          headers: {
            accept: "application/json",
            token: accessToken,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setGoogleCalendarExists(data.status);
        } else {
          console.error("Failed to fetch Google Calendar data");
        }
      } catch (error) {
        console.error("Error fetching Google Calendar data:", error);
      }
    };
    fetchGoogleCalendar();
  }, []);

  const handleMenuItemClick = (path) => {
    if (path === "/calendar-board" && !googleCalendarExists) {
      setShowPopup(true);
    } else {
      setSelectedMenuItem(path);
      localStorage.setItem("selectedMenuItem", path);
      if (path === "/calendar-board") {
        navigate(path);
      }
    }
  };

  const toggleMenu = (menu) => {
    switch (menu) {
      case "dashboard":
        setDashboardMenuOpen(!dashboardMenuOpen);
        setIntegrationsMenuOpen(false);
        break;
      case "integrations":
        setIntegrationsMenuOpen(!integrationsMenuOpen);
        setDashboardMenuOpen(false);
        break;
      default:
        break;
    }
  };

  const handleLinkClick = (path) => {
    setSelectedMenuItem(path);
    localStorage.setItem("selectedMenuItem", path);
    setIsMenuOpen(false); 
  };

  const handleCheckboxClick = () => {
    setIsMenuOpen(false); 
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <h2>Connect Calendar</h2>
            <p>Please connect your calendar first.</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
      <div className={`top-bar adminTopBar ${isMenuOpen ? "menu-open" : "menu-closed"}`}>
        <div className="img_wrapper">
          <Link to="/profile-dashboard">
            <div className="img">
              <img src={userProfile} alt="userProfile" />
            </div>
          </Link>
        </div>
        <div
          className={`mobile_sec open_close ${
            dashboardMenuOpen || integrationsMenuOpen ? "menu-open" : ""
          }`}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <span className="openSpan"></span>
          <span className="openSpan"></span>
          <span className="openSpan"></span>
        </div>
      </div>
      <div className={`main-board ${isMenuOpen ? "menu-open" : ""}`}>
        <div className={`db-signoutBtn left-board ${isMenuOpen ? "open" : "closed"}`}>
          <div className="left-board-inner">
            <div className="left-side-menu-bar">
              <div className="lb-board-heading">
                <h2 className="lb-head">Dashboard</h2>
                <div className={`lb-head-icon ${dashboardMenuOpen ? "showDropdown" : ""}`} onClick={() => toggleMenu("dashboard")}>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
              </div>
              <div className={`lb-menu-list ${dashboardMenuOpen ? "showDropdown" : ""}`}>
                {[
                  { label: "Reports", path: "/admin-Dashboard/crmReports" },
                  { label: "Analytics", path: "/admin-Dashboard/calenderMeeting" },
                  { label: "Questions", path: "/admin-Dashboard/questions" },
                ].map((item, index) => (
                  <label key={index} className="checkbox-container">
                    <input
                      type="radio"
                      id={`menuItem${index}`}
                      checked={selectedMenuItem === item.path}
                      onChange={() => handleMenuItemClick(item.path)}
                      onClick={handleCheckboxClick} 
                    />
                    <span className="checkmark"></span>
                    <Link
                      className="pm-link"
                      to={item.path}
                      onClick={() => handleLinkClick(item.path)}
                    >
                      <span className={`pm-span ${selectedMenuItem === item.path ? "active" : ""}`}>
                        {item.label}
                      </span>
                    </Link>
                  </label>
                ))}
              </div>
            </div>

            <div className="left-side-menu-bar calendar_bar">
              <div className="lb-board-heading">
                <h2 className="lb-head">Calendar</h2>
              </div>
              <div className="lb-menu-list showDropdown">
                {calendarMenuItems.map((item, index) => (
                  <label key={index} className="checkbox-container">
                    <input
                      type="radio"
                      id={`menuItem${index}`}
                      checked={selectedMenuItem === item.path}
                      onChange={() => handleMenuItemClick(item.path)}
                      onClick={handleCheckboxClick} 
                    />
                    <span className="checkmark"></span>
                    <Link
                      className="pm-link"
                      to={item.path}
                      onClick={() => handleLinkClick(item.path)}
                    >
                      <span className={`pm-span ${selectedMenuItem === item.path ? "active" : ""}`}>
                        {item.label}
                      </span>
                    </Link>
                  </label>
                ))}
              </div>
            </div>

            <div className="left-side-menu-bar">
              <div className="lb-board-heading">
                <h2 className="lb-head">Integrations</h2>
                <div className={`lb-head-icon ${integrationsMenuOpen ? "showDropdown" : ""}`} onClick={() => toggleMenu("integrations")}>
                  <i className="fa-solid fa-angle-down"></i>
                </div>
              </div>
              <div className={`lb-menu-list ${integrationsMenuOpen ? "showDropdown" : ""}`}>
                {[
                  { label: "CRM", path: "/admin-Dashboard/crm" },
                ].map((item, index) => (
                  <label key={index} className="checkbox-container">
                    <input
                      type="radio"
                      id={`menuItem${index}`}
                      checked={selectedMenuItem === item.path}
                      onChange={() => handleMenuItemClick(item.path)}
                      onClick={handleCheckboxClick} 
                    />
                    <span className="checkmark"></span>
                    <Link
                      className="pm-link"
                      to={item.path}
                      onClick={() => handleLinkClick(item.path)}
                    >
                      <span className={`pm-span ${selectedMenuItem === item.path ? "active" : ""}`}>
                        {item.label}
                      </span>
                    </Link>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="right-board">
          <Outlet />
        </div>
      </div>
    </>
  );
}
