import React from "react";
import msgBox from '../Assets/msg-box.png';
import lock from '../Assets/lock-con.png';
import { Link } from "react-router-dom";
import './calendar.css';

export default function CalendarInfo() {
  return (
    <>
      <div className="calendar-head">
        <h2>Additional Info</h2>
        <div className="calendar-icon">
         <Link to="/calendar-board"><i className="fa-solid fa-xmark"></i></Link> 
        </div>
      </div>
      <hr />
<br/>
      <div className="questions-block">
        <h3>Invite Question</h3>
        
        <div className="main-question">
          <div className="questions ques1">
            <div className="ques-icon">
              <img src={msgBox} alt="msgBox" />
              <span className="ques-text">Name, Mail</span>
            </div>
            
            <div className="ques-menu">
            <i class="fa-solid fa-ellipsis-vertical"></i>
            </div>
          </div>
          <div className="questions ques2">
            <div className="ques-icon">
              <img src={lock} alt="msgBox" />
              <span className="ques-text">Allow invite to add question</span>
            </div>
            
            <div className="ques-menu">
            <i class="fa-solid fa-ellipsis-vertical"></i>
            </div>
          </div>
          <div className="questions ques3">
            <div className="ques-icon ci-ques-icon">
              <img src={msgBox} alt="msgBox" />
              <span className="ques-text">Q1: Please share anything that will help prepare for our meeting.<br/><br/>Multiple lines</span>
            </div>
            
            <div className="ques-menu">
            <i class="fa-solid fa-ellipsis-vertical"></i>
            </div>
          </div>
        </div>

        <div className="add-que-btn">
          <Link>+ Add new question</Link>
        </div>
      </div>

      <div className="calender-btns">
          <button className="primary-btn secondary">Cancel</button>
          <button className="primary-btn">Save and close</button>
        </div>
    </>
  );
}

