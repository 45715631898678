import React from "react";
import msgBox from "../Assets/msg-box.png";
import textBox from "../Assets/text-box.png";
import lockIcon from "../Assets/lock-con.png";
import './calendar.css';
import { Link } from "react-router-dom";

export default function CalendarCommunicate() {
  return (
    <>
      <div className="calendar-head">
        <h2>Communication</h2>
        <div className="calendar-icon">
         <Link to="/calendar-board"><i className="fa-solid fa-xmark"></i></Link> 
        </div>
      </div>
      <hr />

      <div className="questions-block">
        <p>Send automatic communication for your events</p>
        <h3>Basic Notification</h3>

        <div className="main-question">
          <div className="questions ques1">
            <div className="ques-icon">
              <img src={msgBox} alt="msgBox" />
              <div className="ques-text">
                <p>Calender Invitation</p>
                <span>Immediate after booking</span>
              </div>
            </div>
            <div className="ques-menu">
            <i className="fa-solid fa-ellipsis-vertical"></i>
            </div>
          </div>

          <div className="questions ques2">
            <div className="ques-icon">
              <img src={msgBox} alt="msgBox" />
              <div className="ques-text">
                <p>Email Reminders</p>
              </div>
            </div>
            <div className="ques-menu">
              <div className="on-of-btn">
                <p>OFF</p>
              </div>
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </div>
          </div>
          <div className="questions ques3">
            <div className="ques-icon">
              <img src={textBox} alt="textBox" />
              <div className="ques-text">
                <p>Text Reminders</p>
              </div>
            </div>
            <div className="ques-menu">
              <div className="on-of-btn">
                <p>OFF</p>
              </div>
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </div>
          </div>
          <div className="questions ques4">
            <div className="ques-icon">
              <img src={msgBox} alt="msgBox" />
              <div className="ques-text">
                <p>Email Follow-up</p>
              </div>
            </div>
            <div className="ques-menu">
              <div className="on-of-btn">
                <p>OFF</p>
              </div>
              <i className="fa-solid fa-ellipsis-vertical"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="advance-notify">
        <h3>Advance Notifications</h3>
        <div className="notify-block">
          <div className="notigy-block-inr">
            <div className="notify-inner">
            <div className="notify-block-icon">
              <img src={lockIcon} alt="LockIcon" />
            </div>
            <div className="notify-block-text">
              <p>Only the owner of the event can make changes to workflow</p>
            </div>
            </div>
          </div>
          <div className="notigy-block-inr">
            <p>None</p>
          </div>
        </div>
      </div>

      <div className="calender-btns">
        <button className="primary-btn secondary">Cancel</button>
        <button className="primary-btn">Save and close</button>
      </div>
    </>
  );
}
