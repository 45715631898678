import React, { useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import "../ProfileManagement/userMain.css";
import calendarEvent from '../Assets/calendarEvents.png';
import infoIcon from '../Assets/info.png';

export default function CalendarBoard() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  return (
    <>
      <div className="calendar-top-bar">
        <Link to="/admin-Dashboard">
          <i className="fa-solid fa-xmark"></i>
        </Link>
      </div>
      <div className="main-board">
        <div className={`left-board ${isMenuOpen ? "open" : "closed"}`}>
          <div className="mobile_sec open_close" onClick={toggleMenu}>
            <span className="openSpan"></span>
            <span className="openSpan"></span>
            <span className="openSpan"></span>
          </div>
          <div className="left-board-inner">
            <h2 className="lb-head">Calendar/Meetings</h2>
            <hr />

            <div className="lb-menu-list">
              <button
                className={`pd-btn ${
                  isActive("/calendar-board/events") ? "active-btn" : ""
                }`}
              >
                <Link className="pm-link" to="/calendar-board/events">
                <img src={calendarEvent} alt="calendarEvent" />
                  <span className="pm-span">Events</span>
                </Link>
              </button>
              <button
                className={`pd-btn ${
                  isActive("/calendar-board/meetings") ? "active-btn" : ""
                }`}
              >
                <Link className="pm-link" to="/calendar-board/meetings">
                  <i className="fa-solid fa-users"></i>
                  <span className="pm-span">Meeting Settings</span>
                </Link>
              </button>
              
              <button
                className={`pd-btn ${
                  isActive("/calendar-board/info") ? "active-btn" : ""
                }`}
              >
                <Link className="pm-link" to="/calendar-board/info">
                  <img src={infoIcon} alt="additional Info" />
                  <span className="pm-span"> Additional Info</span>
                </Link>
              </button>
              <button
                className={`pd-btn ${
                  isActive("/calendar-board/communicate") ? "active-btn" : ""
                }`}
              >
                <Link className="pm-link" to="/calendar-board/communicate">
                  <i className="fa-solid fa-fax"></i>
                  <span className="pm-span"> Communication</span>
                </Link>
              </button>
              <button
                className={`pd-btn ${
                  isActive("/calendar-board/availability") ? "active-btn" : ""
                }`}
              >
                <Link className="pm-link" to="/calendar-board/availability">
                  <i className="fa-solid fa-circle-check"></i>
                  <span className="pm-span"> Availability</span>
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="right-board">
          <Outlet />
        </div>
      </div>
    </>
  );
}
