import React, { useState, useEffect } from "react";
import "./calendar.css";
import { Link } from "react-router-dom";
import Calendar from 'react-calendar';
import editBtn from '../Assets/edit.png';
import cloneBtn from '../Assets/clone.png';

export default function CalendarAvailability() {
  const defaultDayTimes = {
    Sun: { startTime: "", endTime: "", editing: false, showTimeInputs: false },
    Mon: { startTime: "", endTime: "", editing: false, showTimeInputs: false },
    Tue: { startTime: "", endTime: "", editing: false, showTimeInputs: false },
    Wed: { startTime: "", endTime: "", editing: false, showTimeInputs: false },
    Thu: { startTime: "", endTime: "", editing: false, showTimeInputs: false },
    Fri: { startTime: "", endTime: "", editing: false, showTimeInputs: false },
    Sat: { startTime: "", endTime: "", editing: false, showTimeInputs: false },
  };

  const [dayTimes, setDayTimes] = useState(defaultDayTimes);
  const [view, setView] = useState("list");

  useEffect(() => {
    const savedData = localStorage.getItem("availabilityData");
    if (savedData) {
      setDayTimes(JSON.parse(savedData));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("availabilityData", JSON.stringify(dayTimes));
  }, [dayTimes]);

  const handleEditClick = (day) => {
    const updatedDayTimes = { ...dayTimes };

    // Close any previously opened edit block
    Object.keys(updatedDayTimes).forEach((d) => {
      updatedDayTimes[d].editing = false;
      updatedDayTimes[d].showTimeInputs = false;
    });

    // Open the clicked edit block
    updatedDayTimes[day].editing = true;
    updatedDayTimes[day].showTimeInputs = true;
    setDayTimes(updatedDayTimes);
  };

  const handleSaveClick = (day) => {
    const updatedDayTimes = { ...dayTimes };
    updatedDayTimes[day].editing = false;
    updatedDayTimes[day].showTimeInputs = false;
    setDayTimes(updatedDayTimes);
  };

  const handleInputChange = (day, field, value) => {
    const updatedDayTimes = { ...dayTimes };
    updatedDayTimes[day][field] = value;
    setDayTimes(updatedDayTimes);
  };

  const toggleTimeInputs = (day) => {
    const updatedDayTimes = { ...dayTimes };
    updatedDayTimes[day].showTimeInputs = !updatedDayTimes[day].showTimeInputs;
    setDayTimes(updatedDayTimes);
  };

  const handleViewChange = (view) => {
    setView(view);
  };
  return (
    <>
      <div className="calendar-head">
        <h2>Availability</h2>
        <div className="calendar-icon">
          <Link to="/calendar-board">
            <i className="fa-solid fa-xmark"></i>
          </Link>
        </div>
      </div>
      <hr />
      <div className="avail-schedule">
        <h5>Schedule</h5>
        <div className="scheduleVsView">
          <div className="scheduleSet">
            <input type="text" className="availInnerInput" />{" "}
            <span className="scheduleSpan"> + Create Schedule</span>
          </div>
          <div className="listVsCalendar">
            <div className="listView" onClick={() => handleViewChange("list")}>
              <div className="lv-icon">
                <i className="fa-solid fa-bars"></i>
                <span className="icon-name">List View</span>
              </div>
            </div>
            <div className="calendarView" onClick={() => handleViewChange("calendar")}>
              <div className="cv-icon">
                <i className="fa-solid fa-calendar-days"></i>
                <span className="icon-name">Calendar View</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="availabilty-main-div">
        <div className="ac-head">
          <h6>Available on</h6>
          <div className="ac-head-inr">
            <h6>Time Zone</h6>
            <p className="ac-para">Indian standard time</p>
          </div>
        </div>
        <hr/>
        {view === "list" && (
          <div className="lv-availability-calendar">
            <div className="calendar-main">
              <div className="calendar-main-head">
                <h6>Weekly hour</h6>
              </div>
              <div className="vl-list">
                <ul className="avail-ul">
                  {Object.entries(dayTimes).map(([day, data]) => (
                    <li className={`availList-li ${day}`} key={day}>
                     <div className="availListLi">
                     <div className={`week-day ${day}`}>{day}</div>
                      {data.editing ? (
                        <>
                          <div className={`avail-editMode ${day}`}>
                            <div className={`switch-container ${day}`}>
                              <span className={`switch ${day}`}>
                                <input
                                  id={`${day}-switch-toggle`}
                                  type="checkbox"
                                  role="switch"
                                  aria-labelledby={`${day}-switch-label`}
                                  checked={data.showTimeInputs}
                                  onChange={() => toggleTimeInputs(day)}
                                />
                                <span className={`slider ${day}`}></span>
                              </span>
                              
                              <span
                                id={`${day}-switch-status`}
                                className={`switch-status ${day}`}
                                aria-hidden="true"
                              >
                                {data.showTimeInputs
                                  ? "Available"
                                  : "Not Available"}
                              </span>
                              <span className="closePopupIcon">
                                <i class="fa-solid fa-xmark"></i>
                              </span>
                            </div>
                            {data.showTimeInputs && (
                              <>
                                <div className={`switch-containerDiv ${day}`}>
                                  Set Time
                                </div>
                                <input
                                  type="text"
                                  className={`tertiary tertiaryInput ${day}`}
                                  placeholder="Start Time"
                                  value={data.startTime}
                                  onChange={(e) =>
                                    handleInputChange(
                                      day,
                                      "startTime",
                                      e.target.value
                                    )
                                  }
                                />
                                <span>to</span>
                                <input
                                  type="text"
                                  className={`tertiary tertiaryInput ${day}`}
                                  placeholder="End Time"
                                  value={data.endTime}
                                  onChange={(e) =>
                                    handleInputChange(
                                      day,
                                      "endTime",
                                      e.target.value
                                    )
                                  }
                                />
                              </>
                            )}
                            <br />
                            <div className={`saveBtn ${day}`}>
                              <button
                                className={`primary-btn secondary ${day}`}
                                onClick={() => handleSaveClick(day)}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </>
                      ) : (
                        <div className={`dayAvailability ${day}`}>
                          {data.startTime && data.endTime
                            ? `${data.startTime} - ${data.endTime}`
                            : "Unavailable"}
                        </div>
                      )}
                      <div
                        className={`edit-ur-weekday ${day}`}
                        onClick={() => handleEditClick(day)}
                      >
                        <img src={editBtn} alt="EditButton" />
                      </div>
                     </div>
                      <div className="liIconClone">
                        <img src={cloneBtn} alt="CloneButton" />
                      </div>
                    </li>
                    
                  ))}
                </ul>
              </div>
            </div>
            <div className="calendar-data">
              <div className="calendar-data-head">
                <h6>Specific hours</h6>
                <p className="calendarPara">
                  Adjust your availability for specific dates when your hours
                  deviate from your usual weekly schedule
                </p>
                <button className="tertiary">+Add specific hours</button>
              </div>
            </div>
          </div>
        )}
        {view === "calendar" && (
          <div className="cv-availability-calendar">
            <Calendar /> {/* Add the Calendar component here */}
          </div>
        )}
      </div>
    </>
  );
}