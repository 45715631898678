import React, { useEffect, useRef, useState } from "react";
import Chart from "chart.js/auto";
import "./Dashboard.css";
import { useTable } from "react-table";
import { useNavigate } from "react-router-dom";

export default function Questions() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [queries, setQueries] = useState([]);
  const navigate = useNavigate();
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const barChartRef = useRef(null);
  const barChartInstance = useRef(null);
  const [callCount, setCallCount] = useState(0);

  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      console.error("Token not found in local storage");
      navigate("/");
      return;
    }


    fetch("https://dev-api.ivoz.ai/user/overview-analytics?start_date=2024-05-13&end_date=2024-05-20", {
      headers: {
        accept: "application/json",
        token: accessToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Extract call count from the response
        const callCount = data.call_count;
        setCallCount(callCount); // Update the state variable
      })
      .catch((error) => {
        console.error("Error fetching call count:", error);
      });

    fetch("https://dev-api.ivoz.ai/user/queries?page=1&page_size=20", {
      headers: {
        accept: "application/json",
        token: accessToken,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          const extractedQueries = data.map((item, index) => ({
            id: item.time,
            serialNumber: index + 1,
            time: new Date(item.time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' }),
            customerName: item.data.cust_name,
            phoneNumber: item.data.phone,
            query: item.data.query,
            date: new Date(item.time).toLocaleDateString(),
          }));
          setQueries(extractedQueries);
        } else {
          throw new Error("Data is not in expected format");
        }
      })
      .catch((error) => {
        console.error("Error fetching queries:", error);
      });
  }, [navigate]);

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    setSelectedDate(selectedDate);
  };

  const filteredMeetings = selectedDate
  ? queries.filter((meeting) => meeting.date === selectedDate)
  : queries;

  const columns = React.useMemo(
    () => [
      { Header: "S.No", accessor: "serialNumber" },
      { Header: "Time", accessor: "time" },
      { Header: "Date", accessor: "date" },
      { Header: "Customer Name", accessor: "customerName" },
      { Header: "Phone number", accessor: "phoneNumber" },
      { Header: "Query", accessor: "query" },
      
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data: filteredMeetings,
    });

  useEffect(() => {
    if (chartRef && chartRef.current) {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      const ctx = chartRef.current.getContext("2d");

      const filledPercentage = 60;
      const remainingPercentage = 100 - filledPercentage;

      const filledGradient = ctx.createLinearGradient(0, 0, 0, 150);
      filledGradient.addColorStop(0, "#ff7f0e");
      filledGradient.addColorStop(1, "#ffecb3");

      const unfilledColor = "#cccccc";

      chartInstance.current = new Chart(ctx, {
        type: "doughnut",
        data: {
          labels: ["Meeting Reports"],
          datasets: [
            {
              data: [filledPercentage, remainingPercentage],
              backgroundColor: [filledGradient, unfilledColor],
            },
          ],
        },
        options: {
          cutout: "70%",
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          elements: {
            center: {
              text: filledPercentage + "%",
              color: "#000000",
              fontStyle: "Arial",
              sidePadding: 20,
              minFontSize: 20,
              maxFontSize: 40,
              lineHeight: 25,
            },
          },
        },
      });
    }

    if (barChartRef && barChartRef.current) {
      if (barChartInstance.current) {
        barChartInstance.current.destroy();
      }
      const ctx = barChartRef.current.getContext("2d");

      const data = {
        labels: ["In progress", "Done", "Pending"],
        datasets: [
          {
            label: "Meeting Reports",
            data: [242, 172, 785],
            backgroundColor: [
              "#ff7f0e", // Orange for "In progress"
              "#007bff", // Blue for "Done"
              "#6a0dad", // Purple for "Pending"
            ],
            borderWidth: 0,
            barThickness: 11,
          },
        ],
      };

      barChartInstance.current = new Chart(ctx, {
        type: "bar",
        data: data,
        options: {
          indexAxis: "y",
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        },
      });
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
      if (barChartInstance.current) {
        barChartInstance.current.destroy();
      }
    };
  }, []);

  return (
    <>
      <div className="wrapper-main">
        <div className="upperBoard">
          <div className="upperboardInner">
            <div className="uprBoardLeft">
              <div className="totalNumber">
                <div className="tn-inner">
                  <div className="th-head">
                    <h6>Total Calls</h6>
                  </div>
                  <div className="tn-number">
                  <p>{callCount}</p> 
                  </div>
                </div>
                <div className="tn-inner">
                  <div className="th-head">
                    <h6>Queries Request</h6>
                  </div>
                  <div className="tn-number">
                    <p>{queries.length}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="uprBoardRyt">
              <div className="meetingReports">
                <div className="meetingReport-inner">
                  <div className="mr-heading">
                    <h4>Meeting Reports</h4>
                  </div>
                  <div className="reportsSec">
                    <div className="mr-reports">
                      <div className="mrReport-inr">
                        <div className="mrReportdiv">
                          <div className="mr-head">Total Meetings</div>
                          <div className="mr-head-data">242</div>
                        </div>
                        <div className="mrReportdiv">
                          <div className="mr-head">In progress</div>
                          <div className="mr-head-data">172</div>
                        </div>
                        <div className="mrReportdiv">
                          <div className="mr-head">Done</div>
                          <div className="mr-head-data">785</div>
                        </div>
                      </div>
                    </div>
                    <div className="barChart">
                      <canvas ref={barChartRef}></canvas>
                    </div>
                    <div className="circleChart">
                      <canvas ref={chartRef}></canvas>
                    </div>
                  </div>
                </div>
              </div>
              <div className="appointments-report">
                <div className="ar-heading">
                  <h4>All Appointments</h4>
                  <p>11 Feb,2024, Friday</p>
                </div>
                <div className="sorting-detail">
                  <div className="sorting-details">
                    <div className="sorting-btn">
                      <select className="customDate" onChange={handleDateChange}>
                        <option value="">Select Date</option>
                        {queries.map((meeting, index) => (
                          <option key={index} value={meeting.date}>
                            {meeting.date}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="downBoard">
          <div className="meeting-table">
            <div className="table-container">
              <h3>All Customer Queries</h3>
              <table {...getTableProps()}>
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        className={index % 2 === 1 ? "grey-row" : ""}
                      >
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()} className={cell.value.length > 10 ? "long-text" : ""}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
