import React, { useState } from "react";
import "./userMain.css";
import { Outlet, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function UserMain() {
  const [activeButton, setActiveButton] = useState("Profile");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  const toggleMenu = () => {
    // Add this function
    setIsMenuOpen(!isMenuOpen);
  };
  const handleSignOut = () => {
    localStorage.removeItem("accessToken");
    navigate("/");

  };

  return (
    <>
      <div className="top-bar profile-top-bar">
        <Link to='/admin-Dashboard'>
          <i className="fa-solid fa-xmark"></i>
        </Link>
      </div>
      <div className="main-board">
        <div className={`left-board ${isMenuOpen ? "open" : "closed"}`}>
          <div className="mobile_sec open_close" onClick={toggleMenu}>
            <span className="openSpan"></span>
            <span className="openSpan"></span>
            <span className="openSpan"></span>
          </div>
          <div className="left-board-inner">
            <h2 className="lb-head">User profile management</h2>
            <br />

            <div className="lb-menu-list">
              <button
                className={`Profile pd-btn ${
                  activeButton === "Profile" ? "active-btn" : ""
                }`}
                onClick={() => handleButtonClick("Profile")}
              >
                <Link className="pm-link" to="/profile-dashboard/profile">
                  <i className="fa-solid fa-user"></i>
                  <div className="pm-span">Profile Update</div>
                </Link>
              </button>
              <button
                className={`Lock pd-btn ${
                  activeButton === "Lock" ? "active-btn" : ""
                }`}
                onClick={() => handleButtonClick("Lock")}
              >
                <Link className="pm-link" to="/profile-dashboard/password">
                  <i className="fa-solid fa-lock"></i>
                  <div className="pm-span">Change password</div>
                </Link>
              </button>
              <button
                className={`security pd-btn ${
                  activeButton === "security" ? "active-btn" : ""
                }`}
                onClick={() => handleButtonClick("security")}
              >
                <Link
                  className="pm-link"
                  to="/profile-dashboard/authentication"
                >
                  <i className="fa-solid fa-screwdriver-wrench"></i>
                  <div className="pm-span"> Security</div>
                </Link>
              </button>
              <button
                 className="pd-btn" onClick={handleSignOut}
              >
                <Link className="pm-link" to="/">
                <i className="fa-solid fa-right-from-bracket"></i>
                  <div className="pm-span">Sign Out</div>
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="right-board">
          <Outlet />
        </div>
      </div>
    </>
  );
}
